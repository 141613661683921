import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import React, {forwardRef, useEffect} from "react";
import {
    AddBox, NavigateNext,
} from "@material-ui/icons";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import CorporateImage from '../../../assets/img/corporate-1-slider-slide-2.jpg';

function ContactHeader({ redcer,errors, auth, /* functions */ }) {

    useEffect(() => {
        // Initialization
        // if (_.isEmpty(data)) {
        //     getData({});
        // }
    }, []);

    // change in data
    // useEffect(() => {
    //     if (!_.isEmpty(redcer.data)) {
    //         setData(redcer.data);
    //         errors = {};
    //     }
    // }, [redcer.data]);



    return (
        <div className="dark-translucent-bg" style={{backgroundImage: `url(${CorporateImage})`, backgroundPosition: '50% 32%'}}>
            {/* breadcrumb start */}
            {/* ================ */}

            <div className="breadcrumb-container">
                <div className="container">
                    <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
                        <Link color="textPrimary" href="/" >
                            Home
                        </Link>
                        <Link color="textPrimary" href="">
                            Contact
                        </Link>
                    </Breadcrumbs>
                </div>
            </div>
            {/* breadcrumb end */}
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 text-center pv-20">
                        <h2 className="title" data-animation-effect="fadeIn" data-effect-delay={100}> <strong>Contact Us</strong></h2>
                        <div className="separator mt-10" data-animation-effect="fadeIn" data-effect-delay={100} />
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default withRouter(connect(mapStateToProps, {
    //functions
})(ContactHeader));
