var pageX = document.documentElement.clientWidth;
var pageY = document.documentElement.clientHeight;
var mouseY=0;
var mouseX=0;

document.addEventListener('mousemove', event => {
  mouseY = event.pageY;
  var yAxis = (pageY/2-mouseY)/pageY*300;

  mouseX = event.pageX / -pageX;
  var xAxis = -mouseX * 100 - 100;

  var element = document.getElementsByClassName("notfound_box__ghost-eyes")[0]
  if(element !== undefined){
    element.style.transform = 'translate('+ xAxis +'%,-'+ yAxis +'%)';
  }
    // .css({ 'transform': 'translate('+ xAxis +'%,-'+ yAxis +'%)' });
});

