import {
    ADD_BROADCAST_MAIL,
    GET_BROADCAST_MAILS
} from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_BROADCAST_MAIL:
            return {
                ...state,
                newMail: action.payload,
                message: action.message
            };
        case GET_BROADCAST_MAILS:
            return {
                ...state,
                alerts: action.payload,
            };
        default :
            return state;
    }
}
