import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import React, {forwardRef, useEffect} from "react";
import {useInput} from "../../common/UseInput";
import {addFeedback} from '../../../actions/feedbacksActions';
import {makeStyles} from "@material-ui/core";
import classnames from 'classnames'
import Banner from '../../../assets/img/banner.jpg'
import Grid from "@material-ui/core/Grid";
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles(theme => ({
    originBanner: {
        // backgroundImage : `url('${Banner}')`,
        // height:500,
        // paddingTop : 100,
        // backgroundSize : 'cover'
    },
}));

function BannerContent({ redcer,errors, auth,addFeedback}) {
    const classes = useStyles();

    const {value: name, setValue: setName, bind: bindName} = useInput("");
    const {value: email, setValue: setEmail, bind: bindEmail} = useInput("");
    const {value: mobile, setValue: setMobile, bind: bindMobile} = useInput("");
    const {value: message, setValue: setMessage, bind: bindMessage} = useInput("");
    const isSmall = useMediaQuery('(max-width:800px)');

    useEffect(() => {
        // Initialization
        // if (_.isEmpty(data)) {
        //     getData({});
        // }
    }, []);

    // change in data
    // useEffect(() => {
    //     if (!_.isEmpty(redcer.data)) {
    //         setData(redcer.data);
    //         errors = {};
    //     }
    // }, [redcer.data]);

    const feedbackSubmit = (e)=>{
        e.preventDefault();
        e.target.reset();
        let data = {
            name,
            email,
            mobile,
            message
        };
        setName("");
        setEmail("");
        setMobile("");
        setMessage("");
        addFeedback(data);
    };


    return (
        <div className={classnames(classes.originBanner,"dark-translucent-bg padding-bottom-clear ")} style={{
            backgroundImage : `url('${Banner}')`,
            height: isSmall? 650 : 500,
            paddingTop : 100,
            backgroundSize : 'cover'
        }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center col-md-offset-0 pv-20 space" style={{display:"inline"}}>
                        <h1 className="title hidden-xs hidden-sm"
                            style={{textTransform: 'none', lineHeight: '1.5', fontSize:33, fontWeight:700}}>BULLISH INDIA
                            Specialist in INTRADAY / BTST &amp; Multibagger Stocks<br/>
                            Join Now
                        </h1>
                        <h3 className="title hidden-md hidden-lg"
                            style={{textTransform: 'uppercase', marginTop: '-24px'}}>BULLISH INDIA
                            Specialist in INTRADAY / BTST &amp; Multibagger Stocks<br/>
                            Join Now
                        </h3>

                        <div className="sorting-filters text-center" style={{
                            backgroundColor: 'rgba(78, 78, 78,0.28)',
                            padding: '10px 1px 1px 1px',
                            borderRadius: '5px'
                        }}>
                            <div className="col-sm-12 col-sm-offset-00" style={{marginTop: 10}}>
                                <h3 className="title" style={{fontSize:24}}>Subscribe Now for future offers</h3>
                                <div className="separator"/>
                            </div>
                            <form  method="post" className="form-inline" onSubmit={feedbackSubmit}
                                  style={{padding: '10px'}} >
                                <Grid container style={{width : "100%"}}>
                                    <Grid item md={4} sm={12} xs={12} className="p-1">
                                        <input type="text" className="form-control" {...bindName}
                                               placeholder="Your Name here" name="name" style={{width: '100%'}}
                                               required/>
                                    </Grid>
                                    <Grid item md={4} sm={12} xs={12} className="p-1">
                                        <input type="email" className="form-control" placeholder="Your Email here" {...bindEmail}
                                               name="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                               style={{width: '100%'}} required/>
                                    </Grid>
                                    <Grid item md={4} sm={12} xs={12} className="p-1">
                                        <input type="text" className="form-control" placeholder="Your Mobile No" {...bindMobile}
                                               name="mobile" pattern="[0123456789][0-9]{9}" style={{width: '100%'}}
                                               required/>
                                    </Grid>
                                </Grid>
                                <Grid container style={{ marginBottom: '10px', width:'100%'}}>
                                    <Grid item md={8} sm={12} xs={12} className="p-1">
                                        <input type="text" className="form-control" placeholder="Your Message" {...bindMessage}
                                               name="message" style={{width: '100%'}} required/>
                                    </Grid>
                                    <Grid item md={4} sm={12} xs={12} className="p-1">
                                        <input type="submit" name="sub" className="btn btn-default" style={{width:"100%"}}/>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* section start */}
            {/* ================ */}
            {/* section end */}
        </div>
    )
}


const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default withRouter(connect(mapStateToProps, {
    addFeedback
})(BannerContent));
