import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import React, {forwardRef, useEffect} from "react";
import Paper from "@material-ui/core/Paper";
import {
    AddBox,
} from "@material-ui/icons";
import _ from "lodash";
import {useInput} from "../../common/UseInput";
import {addFeedback} from '../../../actions/feedbacksActions';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faMobile, faPencilAlt, faUser} from "@fortawesome/free-solid-svg-icons";


function ContactForm({ redcer,errors, auth, addFeedback }) {

    const {value: name, setValue: setName, bind: bindName} = useInput("");
    const {value: email, setValue: setEmail, bind: bindEmail} = useInput("");
    const {value: mobile, setValue: setMobile, bind: bindMobile} = useInput("");
    const {value: message, setValue: setMessage, bind: bindMessage} = useInput("");

    useEffect(() => {
        // Initialization
        // if (_.isEmpty(data)) {
        //     getData({});
        // }
    }, []);

    // change in data
    // useEffect(() => {
    //     if (!_.isEmpty(redcer.data)) {
    //         setData(redcer.data);
    //         errors = {};
    //     }
    // }, [redcer.data]);

    const feedbackSubmit = (e)=>{
        e.preventDefault();
        e.target.reset();
        let data = {
            name,
            email,
            mobile,
            message
        };
        setName("");
        setEmail("");
        setMobile("");
        setMessage("");
        addFeedback(data);
    };


    return (
        <div>
            <p className="lead">It would be great to hear from you! Just drop us a line and ask for
                anything with which you think we could be helpful. We are looking forward to hearing
                from you!</p>
            <div className="contact-form">
                <form method="post" action="" name="myForm" onSubmit={feedbackSubmit}>
                    <div className="form-group position-relative">
                        <label htmlFor="name">Name*</label>
                        <input type="text" className="form-control" id="first_name" name="name" {...bindName}
                               placeholder required/>
                        <FontAwesomeIcon icon={faUser} style={{
                            position: "absolute",
                            top : 40,
                            right : 15
                        }}/>
                    </div>
                    <div className="form-group position-relative">
                        <label htmlFor="email">Email*</label>
                        <input type="email" className="form-control" id="email" name="email" placeholder {...bindEmail}
                               pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required/>
                        <FontAwesomeIcon icon={faEnvelope} style={{
                            position: "absolute",
                            top : 40,
                            right : 15
                        }}/>
                    </div>
                    <div className="form-group position-relative">
                        <label htmlFor="mobile">Contact Number*</label>
                        <input type="text" className="form-control" name="mobile" placeholder {...bindMobile}
                               pattern="[0123456789][0-9]{9}" required/>
                        <FontAwesomeIcon icon={faMobile} style={{
                            position: "absolute",
                            top : 40,
                            right : 18
                        }}/>
                    </div>
                    <div className="form-group position-relative">
                        <label htmlFor="message">Message*</label>
                        <textarea className="form-control" rows={6} id="message" name="message" {...bindMessage}
                                  placeholder required defaultValue={""}/>
                        <FontAwesomeIcon icon={faPencilAlt} style={{
                            position: "absolute",
                            top : 40,
                            right : 15
                        }}/>
                    </div>
                    <input type="submit" id="submit" name="submit" defaultValue="Send"
                           className="margin-clear submit-button btn btn-default"/>
                </form>
            </div>

        </div>
    )
}


const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default withRouter(connect(mapStateToProps, {
    addFeedback
})(ContactForm));
