import {ADD_USER, DELETE_USER, DELETE_USER_ACCOUNT, GET_USERS, RESET_PASSWORD, UPDATE_USER} from "../actions/types";

const initialState={
};

export default function(state = initialState,action) {
    switch (action.type) {
        case GET_USERS:
            return {
                ...state,
                users:action.payload
            };
        case DELETE_USER:
        case DELETE_USER_ACCOUNT:
            return {
                ...state,
                message : action.message,
                deletedUser:action.payload
            };
        case UPDATE_USER:
            return {
                ...state,
                message : action.message,
                updatedUser:action.payload
            };
        case RESET_PASSWORD:
            return {
                ...state,
                message : action.message,
                updatedUser:action.payload
            };
        case ADD_USER:
            return {
                ...state,
                message : action.message,
                addedUser:action.payload
            };
        default :return state;
    }
}
