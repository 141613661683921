import {NEW_PASSWORD, RECOVER_PASSWORD, REFRESH_TOKEN, SET_CURRENT_USER} from "../actions/types";
import { isEmpty } from "lodash";

const initialState={
  isAuthenticated : false,
  user : {}
};

export default function(state = initialState,action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return{
        ...state,
        isAuthenticated : !isEmpty(action.payload),
        user:action.payload
      };
    case REFRESH_TOKEN:
      return {
        ...state,
        refeshToken : action.payload
      };
    case RECOVER_PASSWORD:
      return {
        ...state,
        recoverPassword : action.payload,
        recoverPasswordMessage : action.message,
      };
    case NEW_PASSWORD:
      return {
        ...state,
        newPassword : action.payload
      };
    default :return state;
  }
}
