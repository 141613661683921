import {
    GET_ORDER,
    GET_ORDER_STATS
} from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ORDER_STATS:
            return {
                ...state,
                orderStats: action.payload,
            };
        case GET_ORDER:
            return {
                ...state,
                orders: action.payload,
            };
        // case UPDATE_INTRADAY:
        //     return {
        //         ...state,
        //         message : action.message,
        //         updatedIntraday: action.payload
        //     };
        default :
            return state;
    }
}
