import {ADD_COMMON,GET_COMMON,DELETE_COMMON,TOGGLE_COMMON,UPDATE_COMMON} from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_COMMON:
            return {
                ...state,
                [action.collection + "New"]: action.payload,
            };
        case GET_COMMON:
            return {
                ...state,
                [action.collection]: action.payload,
            };
        case DELETE_COMMON:
            return {
                ...state,
                message : action.message,
                [action.collection + "Deleted"] : action.payload
            };
        case TOGGLE_COMMON:
            return {
                ...state,
                message : action.message,
                [action.collection + "Updated"]: action.payload
            };
        case UPDATE_COMMON:
            return {
                ...state,
                message : action.message,
                [action.collection + "Updated"]: action.payload
            };
        default :
            return state;
    }
}
