import {
    GET_TRACK_RECORDS,
    DELETE_TRACK_RECORD,
    TOGGLE_TRACK_RECORD,
 ADD_TRACK_RECORD, UPDATE_TRACK_RECORD
} from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_TRACK_RECORD:
            return {
                ...state,
                message : action.message,
                newTrackRecord: action.payload
            };
        case GET_TRACK_RECORDS:
            return {
                ...state,
                trackRecords: action.payload,
            };
        case DELETE_TRACK_RECORD:
            return {
                ...state,
                message : action.message,
                deletedTrackRecord: action.payload
            };
        case TOGGLE_TRACK_RECORD:
            return {
                ...state,
                message : action.message,
                updatedTrackRecord: action.payload
            };
        case UPDATE_TRACK_RECORD:
            return {
                ...state,
                message : action.message,
                updatedTrackRecord: action.payload
            };

        default :
            return state;
    }
}
