import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Origin from './pages/Origin'
import jwt_decode from "jwt-decode";

import store from "./redux/store";
import {setCurrentUser, logoutUser} from "./actions/authActions";
import {setAuthToken} from "./Utils/Auth";
import {Provider} from "react-redux";
import PrivateRoute from "./Components/common/PrivateRoute";
import {SnackbarProvider, useSnackbar} from 'notistack';
import Loadable from 'react-loadable';
import NotFoundPage from './Components/common/NotFoundPage'

const LoadableDashboard = Loadable({
    loader: () => import('./pages/DashboardPage'),
    loading(){
        return <div/>;
    },
});

const LoadableLogin = Loadable({
    loader: () => import('./pages/LoginPage'),
    loading(){
        return <div/>;
    },
});

const LoadableSuccess = Loadable({
    loader: () => import('./pages/Success'),
    loading(){
        return <div/>;
    },
});

const LoadablePasswordReset = Loadable({
    loader: () => import('./pages/PasswordReset'),
    loading(){
        return <div/>;
    },
});

const LoadableNewPasswordPage = Loadable({
    loader: () => import('./pages/NewPasswordPage'),
    loading(){
        return <div/>;
    },
});


function App() {


    if (localStorage.jwtToken) {
        setAuthToken(localStorage.jwtToken);
        const decoded = jwt_decode(localStorage.jwtToken);
        store.dispatch(setCurrentUser(decoded));

        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            store.dispatch(logoutUser());
            // window.location.href = "/login";
        }
    }

    return (
        <Provider store={store}>
            <Router>
                <SnackbarProvider maxSnack={3}>
                    <Switch>
                        <PrivateRoute path="/dashboard" component={LoadableDashboard}/>
                        <Route exact path="/admin" component={LoadableLogin}/>
                        <Route exact path="/password-reset" component={LoadablePasswordReset}/>
                        <Route exact path="/new-password/:code" component={LoadableNewPasswordPage}/>
                        <Route exact path="/success" component={LoadableSuccess}/>
                        <Route path="/not-found" component={NotFoundPage}/>
                        <Route path="/" component={Origin}/>
                    </Switch>
                </SnackbarProvider>
            </Router>
        </Provider>
    );
}

export default App;
