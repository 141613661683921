import {DELETE_FEED, GET_FEEDS, ADD_FEED, UPDATE_FEED} from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_FEEDS:
            return {
                ...state,
                feeds: action.payload
            };
        case DELETE_FEED:
            return {
                ...state,
                message : action.message,
                feedDeleted: true
            };
        case ADD_FEED:
            return {
                ...state,
                message : action.message,
                feedAdded: true
            };
        case UPDATE_FEED:
            return {
                ...state,
                message : action.message,
                feedUpdated: true
            };
        default :
            return state;
    }
}
