import {
    ADD_BLOG_POST,
    GET_BLOG_POSTS,
    DELETE_BLOG_POST,
    GET_BLOG_ARCHIVES
} from "../actions/types";

const initialState = {
    categories: [
        {key: "Indian Stock Market", value: "indian_stock_market"},
        {key: "Stock Market", value: "stock_market"},
        {key: "Multibagger Stocks", value: "multibagger_stocks"},
        {key: "Market", value: "market"},
        // {key: "Uncategoried", value: "uncategorised"},
    ]
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_BLOG_POST:
            return {
                ...state,
                message: action.message,
                newBlogPost: action.payload,
            };
        case GET_BLOG_POSTS:
            return {
                ...state,
                blogPosts: action.payload,
            };
        case GET_BLOG_ARCHIVES:
            return {
                ...state,
                archives: action.payload.archives,
            };
        case DELETE_BLOG_POST:
            return {
                ...state,
                message: action.message,
                deletedBlogPost: action.payload
            };
        default :
            return state;
    }
}
