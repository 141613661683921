import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import langReducer from "./langReducer";
import userReducer from './userReducer';
import feedReducer from './feedReducer';
import orderReducer from './orderReducer';
import intradayReducer from './intradayReducer';
import notificationReducer from './notificationReducer';
import {loadingBarReducer} from "react-redux-loading-bar";
import testimonialsReducer from "./testimonialsReducer";
import packageReducer from "./packageReducer";
import feedbacksReducer from "./feedbacksReducer";
import multibaggerReducer from "./multibaggerReducer";
import configReducer from "./configReducer";
import alertReducer from "./alertsReducer";
import trackRecordsReducer from "./trackRecordsReducer";
import originReducer from "./originReducer";
import newsFeedReducer from "./newsFeedReducer";
import blogReducer from "./blogReducer";
import mailReducer from "./mailReducer";
import commonReducer from "./commonReducer";

export default combineReducers({
  auth : authReducer,
  errors : errorReducer,
  lang : langReducer,
  user : userReducer,
  feed : feedReducer,
  order : orderReducer,
  intraday : intradayReducer,
  notification: notificationReducer,
  testimonial : testimonialsReducer,
  package : packageReducer,
  feedback : feedbacksReducer,
  multibagger : multibaggerReducer,
  config : configReducer,
  alert:alertReducer,
  trackRecord:trackRecordsReducer,
  origin : originReducer,
  newsFeed : newsFeedReducer,
  blog : blogReducer,
  mail : mailReducer,
  common : commonReducer,
  loadingBar: loadingBarReducer,
});
