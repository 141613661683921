import axios from "axios";
import {
    ADD_FEEDBACK,
    DELETE_FEEDBACK,
    GET_FEEDBACK,
} from "./types";
import {showLoading,hideLoading} from 'react-redux-loading-bar';
import {handleResponse,negativeResponse} from '../Utils/ResponseManager';

export const getFeedbacks = requestData => dispatch => {
    dispatch(showLoading());
    axios.get("/api/feedbacks",requestData)
        .then(res => {
            dispatch(hideLoading());
            dispatch(handleResponse(res.data,GET_FEEDBACK));
        }).catch(err => {
            dispatch(negativeResponse(err));
        });
};

export const deleteFeedback = requestData => dispatch => {
    dispatch(showLoading());
    axios.delete(`/api/feedbacks/${requestData._id}`)
        .then(res => {
            dispatch(hideLoading());
            dispatch(handleResponse(res.data,DELETE_FEEDBACK));
        }).catch(err => {
        dispatch(negativeResponse(err));
    });
};

export const addFeedback = requestData => dispatch => {
    dispatch(showLoading());
    axios.post('/api/feedbacks',requestData)
        .then(res => {
            dispatch(hideLoading());
            dispatch(handleResponse(res.data,ADD_FEEDBACK));
        }).catch(err => {
        dispatch(negativeResponse(err));
    });
};




