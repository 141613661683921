import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import React, {forwardRef, useEffect} from "react";
import Paper from "@material-ui/core/Paper";
import {
    AddBox,
} from "@material-ui/icons";
import _ from "lodash";


function BannerAlerts({ redcer,errors, auth, origin }) {
    const [data, setData] = React.useState({});

    useEffect(() => {
        if (!_.isEmpty(origin.data)) {
            setData(origin.data);
            errors = {};
        }
    }, [origin.data]);


    return (
        <div className="header-top dark " style={{position: 'relative', top: '-54px', zIndex: 99, backgroundColor: '#565656', borderBottom: '0px solid #252a2c', padding: '0px 0'}}>
            <div className="container-fluid ">
                <div className="row">
                    <div className="col-lg-1 col-md-1 col-xs-3 col-sm-3 " style={{backgroundColor: 'rgba(2, 20, 234, 0.52)'}}>
                        {/* header-top-first start */}
                        {/* ================ */}
                        <div className="header-top-first clearfix">
                            <p className="stockes" style={{fontSize: '20px', marginTop: '6px', padding: '8px 0px 0px 6px'}}><strong>ALERTS</strong></p>
                        </div>
                        {/* header-top-first end */}
                    </div>
                    <div className="col-lg-11 col-md-10 col-xs-9 col-sm-9">
                        {/* header-top-second start */}
                        {/* ================ */}
                        <div id="header-top-second" className="clearfix text-right">
                            <p style={{fontSize: '16px', marginTop: '12px'}}>
                                <marquee> {data.alerts && data.alerts.map(alert=>(<span key={alert._id} style={{marginRight : "40px"}}> {alert.text} </span>))}</marquee>
                            </p>
                        </div>
                        {/* header-top-second end */}
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
    origin : state.origin
});

export default withRouter(connect(mapStateToProps, {
    //functions
})(BannerAlerts));
