import {GET_ERRORS, SEND_NOTIFICATIONS} from "../actions/types";

const initialState={
};

export default function(state = initialState,action) {
  switch (action.type) {
    case GET_ERRORS:
      return action.payload;
    case SEND_NOTIFICATIONS:
      return {};
    default :return state;
  }
}
