export const GET_ERRORS = 'get_errors';
export const SET_CURRENT_USER = 'set_current_user';
export const RECOVER_PASSWORD = 'recover_password';
export const NEW_PASSWORD = 'new_password';
export const REFRESH_TOKEN = 'refresh_token';
export const GET_USERS = 'get_users';
export const GET_FEEDS = 'get_feeds';
export const GET_NOTIFICATIONS = 'get_notifications';
export const SAVE_GLOBAL_FCM_TOKEN = 'save_global_fcm_token';

export const ADD_CONFIG = 'add_config';
export const GET_CONFIG = 'get_config';
export const UPDATE_CONFIG = 'update_config';
export const UPDATE_CONFIGS = 'update_configs';

export const ADD_FEEDBACK = 'add_feedback';
export const GET_FEEDBACK = 'get_feedback';
export const DELETE_FEEDBACK = 'delete_feedback';

export const GET_ORIGIN_DATA = 'get_origin_data';

export const GET_LATEST_NEWS_FEEDS = 'latest_get_news_feeds';
export const GET_NEWS_FEEDS = 'get_news_feeds';
export const GET_NEWS_FEEDS_STATS = 'get_news_feeds_size';
export const ADD_NEWS_FEED = 'add_news_feed';
export const CLEAR_NEWS_FEED = 'clear_news_feed';
export const UPLOAD_NEWS_FEED_IMAGES = 'upload_news_feed_images';

export const GET_BROADCAST_MAILS = 'get_broadcast_mails';
export const ADD_BROADCAST_MAIL = 'add_broadcast_mail';

export const GET_BLOG_ARCHIVES = 'get_blog_archives';
export const GET_BLOG_POSTS = 'get_blog_posts';
export const ADD_BLOG_POST = 'add_blog_post';
export const DELETE_BLOG_POST = 'delete_blog_post';
export const UPDATE_BLOG_POST = 'update_blog_post';


export const GET_ALERTS = 'get_alerts';
export const ADD_ALERT = 'add_alert';
export const TOGGLE_ALERT = 'toggle_alert';
export const DELETE_ALERT = 'delete_alert';
export const UPDATE_ALERT = 'update_alert';

export const GET_COMMON = 'get_common';
export const ADD_COMMON = 'add_common';
export const TOGGLE_COMMON = 'toggle_common';
export const DELETE_COMMON = 'delete_common';
export const UPDATE_COMMON = 'update_common';

export const GET_TRACK_RECORDS = 'get_track_records';
export const TOGGLE_TRACK_RECORD = 'toggle_track_records';
export const DELETE_TRACK_RECORD = 'delete_track_records';
export const UPDATE_TRACK_RECORD = 'update_track_records';
export const ADD_TRACK_RECORD = 'add_track_record';

export const GET_TESTIMONIALS = 'get_testimonials';
export const TOGGLE_TESTIMONIALS = 'toggle_testimonials';
export const DELETE_TESTIMONIALS = 'delete_testimonials';
export const UPDATE_TESTIMONIALS = 'update_testimonials';

export const GET_PACKAGES = 'get_packages';
export const DELETE_PACKAGE = 'delete_package';
export const UPDATE_PACKAGE = 'update_package';
export const ADD_PACKAGE = 'add_package';

export const GET_MULTIBAGGER = 'get_multibagger';
export const DELETE_MULTIBAGGER = 'delete_multibagger';
export const UPDATE_MULTIBAGGER = 'update_multibagger';
export const ADD_MULTIBAGGER = 'add_multibagger';

export const GET_INTRADAY = 'get_intraday';
export const ADD_INTRADAY = 'add_intraday';
export const DELETE_INTRADAY = 'delete_intraday';
export const UPDATE_INTRADAY = 'update_intraday';

export const GET_ORDER = 'get_order';
export const GET_ORDER_STATS = 'get_order_stats';
export const ADD_ORDER = 'add_order';
export const DELETE_ORDER = 'delete_order';
export const UPDATE_ORDER = 'update_order';

export const SEND_NOTIFICATIONS = 'send_notifications';

export const ADD_TESTIMONIAL = 'add_testimonial';

export const ADD_FEED = 'add_feeds';
export const DELETE_FEED = 'delete_feed';
export const UPDATE_FEED = 'update_feed';

export const ADD_USER = 'add_user';
export const DELETE_USER = 'delete_user';
export const DELETE_USER_ACCOUNT = 'delete_user_account';
export const UPDATE_USER = 'update_user';
export const RESET_PASSWORD = 'reset_password';
export const SET_STATS = 'set_stats';
export const GET_PROFILE = 'get_profile';
export const SET_LANGUAGE = 'set_language';
