import {
    ADD_FEEDBACK,
    ADD_TESTIMONIAL,
    DELETE_FEEDBACK,
    GET_FEEDBACK,
} from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_FEEDBACK:
            return {
                ...state,
                feedbacks: action.payload,
            };
        case DELETE_FEEDBACK:
            return {
                ...state,
                message : action.message,
                deletedFeedback: action.payload
            };
        case ADD_FEEDBACK:
            return {
                ...state,
                message : action.message,
                newFeedback: action.payload
            };
        default :
            return state;
    }
}
