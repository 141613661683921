import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import React, {useEffect} from "react";
import BannerContent from "../../Components/Origin/Dashboard/BannerContent";
import BannerAlerts from "../../Components/Origin/Dashboard/BannerAlerts";
// import NewsFeed from "../../Components/Origin/Dashboard/NewsFeed";
// import ClientComplaints from "../../Components/Origin/Dashboard/ClientComplaints";
// import AppPromo from "../../Components/Origin/Dashboard/AppPromo";
// import WhyChooseUs from "../../Components/Origin/Dashboard/WhyChooseUs";
// import OurServices from "../../Components/Origin/Dashboard/OurServices";
// import LookFor from "../../Components/Origin/Dashboard/LookFor";
// import OurPlans from "../../Components/Origin/Dashboard/OurPlans";
// import Testimonials from "../../Components/Origin/Dashboard/Testimonials";
// import Certification from "../../Components/Origin/Dashboard/Certification";
// import TrackRecord from "../../Components/Origin/Dashboard/TrackRecord";
import Loadable from "react-loadable";
import _ from 'lodash';
// import {Helmet} from "react-helmet";

const LoadableSections = Loadable.Map({
    loader: {
        NewsFeed: () => import("../../Components/Origin/Dashboard/NewsFeed"),
        ClientComplaints: () => import("../../Components/Origin/Dashboard/ClientComplaints"),
        AppPromo: () => import("../../Components/Origin/Dashboard/AppPromo"),
        WhyChooseUs: () => import("../../Components/Origin/Dashboard/WhyChooseUs"),
        OurServices: () => import("../../Components/Origin/Dashboard/OurServices"),
        LookFor: () => import("../../Components/Origin/Dashboard/LookFor"),
        OurPlans: () => import("../../Components/Origin/Dashboard/OurPlans"),
        Testimonials: () => import("../../Components/Origin/Dashboard/Testimonials"),
        Certification: () => import("../../Components/Origin/Dashboard/Certification"),
        TrackRecord: () => import("../../Components/Origin/Dashboard/TrackRecord"),
        OriginFooter: () => import("../../Components/Origin/OriginFooter"),
    },
    loading() {
        return <div/>;
    },
    render(loader, props) {
        let NewsFeed = loader.NewsFeed.default;
        let ClientComplaints = loader.ClientComplaints.default;
        let AppPromo = loader.AppPromo.default;
        let WhyChooseUs = loader.WhyChooseUs.default;
        let OurServices = loader.OurServices.default;
        let LookFor = loader.LookFor.default;
        let OurPlans = loader.OurPlans.default;
        let Testimonials = loader.Testimonials.default;
        let Certification = loader.Certification.default;
        let TrackRecord = loader.TrackRecord.default;
        let OriginFooter = loader.OriginFooter.default;

        return (
            <div>
                <NewsFeed/>
                <ClientComplaints/>
                <AppPromo/>
                <WhyChooseUs/>
                <OurServices/>
                <LookFor/>
                <OurPlans/>
                <Testimonials/>
                <Certification/>
                <TrackRecord/>
                <OriginFooter/>
            </div>
        )
    }
});

function OriginHomePage({redcer, errors, auth, origin}) {

    useEffect(() => {
        // Initialization
        // if (_.isEmpty(data)) {
        //     getData({});
        // }
    }, []);

    // change in data
    // useEffect(() => {
    //     if (!_.isEmpty(redcer.data)) {
    //         setData(redcer.data);
    //         errors = {};
    //     }
    // }, [redcer.data]);


    return (
        // <div>
        //     <BannerContent/>
        //     <BannerAlerts/>
        //     <NewsFeed/>
        //     <ClientComplaints/>
        //     <AppPromo/>
        //     <WhyChooseUs/>
        //     <OurServices/>
        //     <LookFor/>
        //     <OurPlans/>
        //     <Testimonials/>
        //     <Certification/>
        //     <TrackRecord/>
        // </div>
        <div>
            {/*{ origin.data && origin.data.config &&*/}
            {/*    <Helmet>*/}
            {/*        <meta charSet="utf-8" />*/}
            {/*        {!_.isEmpty(origin.data.config.homeTitle) && <title>{origin.data.config.homeTitle}</title>}*/}
            {/*        {!_.isEmpty(origin.data.config.homeDesc) && <meta name="description" content={origin.data.config.homeDesc} />}*/}
            {/*        {!_.isEmpty(origin.data.config.homeTitle) && <meta property="og:title" content={origin.data.config.homeTitle} />}*/}
            {/*        {!_.isEmpty(origin.data.config.homeDesc) && <meta property="og:description" content={origin.data.config.homeDesc}/>}*/}
            {/*        <link rel="canonical" href="https://bullishindia.com" />*/}
            {/*    </Helmet>*/}
            {/*}*/}
            <BannerContent/>
            <BannerAlerts/>
            <LoadableSections/>
        </div>
    )
}


const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
    origin: state.origin,
});

export default withRouter(connect(mapStateToProps, {
    //functions
})(OriginHomePage));
