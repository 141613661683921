import {
    ADD_CONFIG,
    GET_CONFIG,
    UPDATE_CONFIG, UPDATE_CONFIGS
} from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CONFIG:
            return {
                ...state,
                configs: action.payload,
            };
        case UPDATE_CONFIG:
            return {
                ...state,
                message : action.message,
                updatedConfig: action.payload
            };
        case UPDATE_CONFIGS:
            return {
                ...state,
                message : action.message,
                updatedConfigs: action.payload
            };
        case ADD_CONFIG:
            return {
                ...state,
                message : action.message,
                newConfig: action.payload
            };
        default :
            return state;
    }
}
