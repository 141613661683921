import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import React, {forwardRef, useEffect} from "react";
import {capitalize} from "@material-ui/core/utils";
import {makeStyles} from "@material-ui/core";
import classnames from 'classnames'
import LogoLightBlue from '../../../assets/img/logo_light_blue.png';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    bgGradient: {
        // background: "#C9D6FF";
        // background: "-webkit-linear-gradient(to right, #E2E2E2, #C9D6FF)";
        background: "linear-gradient(to right, #E2E2E2, #C9D6FF)"
    },
    ulli: {
        marginBottom: "1.4rem"
    },
    pricingDivider: {
        /*border-radius: 20px;*/
        background: "#C64545",
        padding: "1em 0 4em",
        position: "relative"
    },
    blue__pricing_divider: {
        "background": "#2D5772"
    },
    green__pricing_divider: {
        "background": "#1AA85C"
    },
    red_b: {
        "color": "#C64545"
    },
    blue_b: {
        "color": "#2D5772"
    },
    green_b: {
        "color": "#1AA85C"
    },
    pricing_divider_img: {
        "position": "absolute", "bottom": "-2px", "left": "0", "width": "100%", "height": "80px"
    },


}));

function IntraItem({intraData, errors, auth, /* functions */}) {
    const classes = useStyles();

    useEffect(() => {
        // Initialization
        // if (_.isEmpty(data)) {
        //     getData({});
        // }
    }, []);

    // change in data
    // useEffect(() => {
    //     if (!_.isEmpty(redcer.data)) {
    //         setData(redcer.data);
    //         errors = {};
    //     }
    // }, [redcer.data]);


    return (
        <div id="redIntradayLayout" className={classnames(classes.princingItem,"col-lg-4 col-md-4 col-sm-6 col-xs-12 princingItem",{[classes.green_b]:intraData.action === "buy"},{[classes.red_b]:intraData.action !== "buy"})}
             style={{paddingBottom: '15px', paddingTop: '15px'}}>
            <div className={classnames(classes.pricingDivider,{[classes.green__pricing_divider]:intraData.action === "buy"} )}>
                <h6 className="my-0 display-6 text-light font-weight-normal mb-0">
                    <div style={{display: '-webkit-inline-box'}}><img src={LogoLightBlue} style={{
                        height: '35px',
                        width: '120px',
                        display: 'inline'
                    }}/>
                        <h4 className="text-light intraDate" style={{marginLeft: '60px'}}>{new Date(intraData.date).toLocaleDateString("en-US")}</h4></div>
                </h6>
                <h3 className="text-light intraName">{intraData.name}</h3>
                <h6 className="text-center text-light intraType">{capitalize(intraData.action)}</h6>
                <svg className={classes.pricing_divider_img} enableBackground="new 0 0 300 100" height="100px" id="Layer_1"
                     preserveAspectRatio="none" version="1.1" viewBox="0 0 300 100" width="300px" x="0px"
                     xmlSpace="preserve" xmlnsXlink="https://www.w3.org/1999/xlink" xmlns="https://www.w3.org/2000/svg"
                     y="0px">
                    <path className={classnames(classes.decoLayer,"decoLayer deco-layer--1")} d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
	c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z" fill="#FFFFFF" opacity="0.6"/>
                    <path className={classnames(classes.decoLayer,"decoLayer deco-layer--2")} d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
	c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z" fill="#FFFFFF" opacity="0.6"/>
                    <path className={classnames(classes.decoLayer,"decoLayer deco-layer--3")} d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
	H42.401L43.415,98.342z" fill="#FFFFFF" opacity="0.7"/>
                    <path className={classnames(classes.decoLayer,"decoLayer deco-layer--4")} d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
	c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z" fill="#FFFFFF"/>
                </svg>
            </div>
            <div className="card-body bg-white mt-0 shadow">
                <table style={{marginBottom: 0}} className="table table-striped">
                    <tbody>
                    <tr>
                        <td><b>{intraData.action === "buy" ? 'Buy at' : 'Sell at'}</b></td>
                        <td><strong className="intraBuyAt">{intraData.buyAt}</strong></td>
                    </tr>
                    <tr>
                        <td><b>{intraData.action === "buy" ? 'Today\'s Target' : 'Take profit at'}</b></td>
                        <td><strong className="intraTarget">{intraData.target}</strong></td>
                    </tr>
                    <tr>
                        <td><b>{intraData.action === "buy" ? 'Stop loss at' : 'Stop loss at'}</b></td>
                        <td><strong className="intraLossAt">{intraData.lossAt}</strong></td>
                    </tr>
                    </tbody>
                </table>
                {/*ul class="list-unstyled mb-5 position-relative">
                          <li><b>Sell at</b> 6600 - 6610</li>
                          <li><b>Take profit at</b> 6470 - 6401 - 6370 - 6300</li>
                          <li><b>Stop loss at</b> 6700</li>
                        </ul*/}
            </div>
        </div>
    )
}


const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default withRouter(connect(mapStateToProps, {
    //functions
})(IntraItem));
