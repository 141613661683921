import {connect} from "react-redux";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import React, { useEffect} from "react";
// import _ from "lodash";
import OriginHeader from "../../Components/Origin/OriginHeader";
import OriginNav from "../../Components/Origin/OriginNav";
import OriginHomePage from "../Origin/OriginHomePage";
import {getOriginData} from '../../actions/originActions';
import {saveGlobalToken} from '../../actions/notificationActions';
import Loadable from "react-loadable";
import {Messaging} from '../../Utils/FirebaseHelper';
import Script from 'react-load-script'
import ReactGA from 'react-ga';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js'
import "../../Components/Styling/bootstrapNav.css"
import "../../Components/Styling/typography-default.css"
// import "../../Components/Styling/orginNav.css"
import "../../Components/Styling/style.css"
// import {Helmet} from "react-helmet";
// import "../../assets/bootstrap/css/bootstrap.css"
import ContactPage from './ContactPage'
import IntradayPage from './IntradayPage'
import RedirectWrapper from "../../Components/common/RedirectWrapper";

const LoadableAboutPage = Loadable({
    loader: () => import('./AboutPage'),
    loading(){
        return <div/>;
    },
});
const LoadableSubscribePage = Loadable({
    loader: () => import('./SubscribePage'),
    loading(){
        return <div/>;
    },
});
const LoadableMultibaggerPage = Loadable({
    loader: () => import('./MultibaggerPage'),
    loading(){
        return <div/>;
    },
});
// const LoadableIntraPage = Loadable({
//     loader: () => import('./IntradayPage'),
//     loading(){
//         return <div/>;
//     },
// });
const LoadableBlogPage = Loadable({
    loader: () => import('./BlogPage'),
    loading(){
        return <div/>;
    },
});
const LoadablePrivacyPolicy = Loadable({
    loader: () => import('./PrivacyPolicyPage'),
    loading(){
        return <div/>;
    },
});
const LoadableTermsPage = Loadable({
    loader: () => import('./TermsPage'),
    loading(){
        return <div/>;
    },
});
const LoadableDisclaimerPage = Loadable({
    loader: () => import('./Disclaimer'),
    loading(){
        return <div/>;
    },
});
const LoadableDeleteAccountPage = Loadable({
    loader: () => import('./DeleteAccountPage'),
    loading(){
        return <div/>;
    },
});
// const LoadableContactPage = Loadable({
//     loader: () => import('./ContactPage'),
//     loading(){
//         return <div/>;
//     },
// });
const LoadableNewsFeedFull = Loadable({
    loader: () => import('../../Components/Origin/Dashboard/NewsFeedFull'),
    loading(){
        return <div/>;
    },
});


// const LoadableRoutes = Loadable.Map({
//     loader: {
//         AboutPage: ()=>import("./AboutPage"),
//         SubscribePage: ()=>import("./SubscribePage"),
//         MultibaggerPage: ()=>import("./MultibaggerPage"),
//         IntradayPage: ()=>import("./IntradayPage"),
//         BlogPage: ()=>import("./BlogPage"),
//         PrivacyPolicy: ()=>import("./PrivacyPolicyPage"),
//         Terms: ()=>import("./TermsPage"),
//         Disclaimer: ()=>import("./Disclaimer"),
//         ContactPage: ()=>import("./ContactPage"),
//         NewsFeedFull: ()=>import("../../Components/Origin/Dashboard/NewsFeedFull"),
//     },
//     loading(){
//         return <div/>;
//     },
//     render(loader,props){
//         return (
//             <Switch>
//                 <Route path='/about' component={loader.AboutPage.default}/>
//                 <Redirect from='/about.php' to='/about' />
//
//                 <Route path='/subscribe' component={loader.SubscribePage.default}/>
//                 <Redirect from='/subscribe.php' to='/subscribe' />
//
//                 <Route path='/multibagger-stocks' component={loader.MultibaggerPage.default}/>
//                 <Redirect from='/multibagger-stocks.php' to='/multibagger-stocks' />
//
//                 <Route path='/intradaycalls' component={loader.IntradayPage.default}/>
//                 <Redirect from='/intradaycalls.php' to='/intradaycalls' />
//
//                 <Route path="/blog" component={loader.BlogPage.default}/>
//                 <Redirect from='/blog.php' to='/blog' />
//
//                 <Route path="/contact" component={loader.ContactPage.default}/>
//                 <Redirect from='/contact.php' to='/contact' />
//
//                 <Route path="/daily-news" component={loader.NewsFeedFull.default}/>
//                 <Redirect from='/daily-news.php' to='/daily-news' />
//
//                 <Route path="/privacy-policy" component={loader.PrivacyPolicy.default}/>
//                 <Redirect from='/privacy-policy.php' to='/privacy-policy' />
//
//                 <Route path="/terms-and-conditions" component={loader.Terms.default}/>
//                 <Redirect from='/terms-and-conditions.php' to='/terms-and-conditions' />
//
//                 <Route path="/disclaimer" component={loader.Disclaimer.default}/>
//                 <Redirect from='/disclaimer.php' to='/disclaimer' />
//
//                 <Redirect from='*' to='/' />
//             </Switch>
//         )
//     }
// });

function Origin({ reducer,errors, auth ,origin,getOriginData,saveGlobalToken,notification,history}) {
    ReactGA.initialize("UA-104166075-2",{debug : process.env.NODE_ENV !== "production"});
    history.listen(location => {
        ReactGA.set({ page: location.pathname }); // Update the user's current page
        ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });

    const metaInfo = {
        contact : {
            title : "Contact Bullish India For the Best Share Tips",
            description : "Contact us for the best share market tips and advice our SEBI Registered experts will guide you the best and provide you with great stock advice."
        },
        blog : {
            title : "Indian Stock Market Blogs | Bullish India",
            description : "Want to learn more about Indian stock market? Read Bullish India blogs and keep yourself updated about the latest trends in the financial market"
        },
        intradaycalls : {
            title : "Free Stock Market Intraday Trading Tips & Calls for Today and Tomorrow",
            description : "Best Indian Share market intraday calls provider company in India. Top Equity advice, strategies and Tips to Invest in India for short term trading."
        },
        ['multibagger-stocks'] : {
            title : "Top Multibagger Stocks For Future | Multibagger Stocks | Bullish India",
            description : "Invest in India's Top Multibagger Stocks for Future by consulting Bullish India, we offer a specialized and personalized stock market research analyst services."
        },
        subscribe : {
            title : "Subscribe for Top Shares to Buy Today in Indian Market | Bullish India",
            description : "We provide stock market and the entire spectrum of research analyst services altogether at one place. Subscribe us now to know more about the top shares to buy today in Indian market."
        },
        about : {
            title : "SEBI Registered Intraday Stock Tips Research Analyst Company | Bullish India",
            description : "Bullish India is a SEBI registered stock tips research analyst company. Get the best multibagger stocks, intraday share tips, recommendations and advices for today and tomorrow!"
        },
    };


    const [data, setData] = React.useState([
    ]);
    // useScript('https://embed.tawk.to/597079d31dc79b329518f515/default');

    useEffect(() => {
        if (data === null || data.length === 0) {
            getOriginData({});

            if(Messaging && Notification){
                Notification.requestPermission().then((permission) => {
                    if (permission === 'granted') {
                        console.log('Notification permission granted.');
                        Messaging.getToken().then((currentToken) => {
                            if (currentToken) {
                                if(localStorage.getItem("tokenSaved") === "false"
                                    || localStorage.getItem("tokenSaved") === undefined
                                    || localStorage.getItem("tokenSaved") === null
                                ){
                                    saveGlobalToken(currentToken)
                                }
                            }
                        }).catch((err) => {
                            localStorage.setItem("tokenSaved",false);
                            console.log('An error occurred while retrieving token. ', err);
                        });
                    } else {
                        localStorage.setItem("tokenSaved",false);
                        console.log('Unable to get permission to notify.');
                    }
                });
                Messaging.onTokenRefresh(() => {
                    Messaging.getToken().then((refreshedToken) => {
                        console.log('Token refreshed.');
                        saveGlobalToken(refreshedToken)
                    }).catch((err) => {
                        localStorage.setItem("tokenSaved",false);
                        console.log('Unable to retrieve refreshed token ', err);
                    });
                });
                Messaging.onMessage((payload) => {
                    console.log('Message received. ', payload);
                    const notificationTitle = payload.notification.title;
                    const notificationOptions = {
                        body: payload.notification.body,
                        icon: payload.notification.icon,
                    };

                    if (!("Notification" in window)) {
                        console.log("This browser does not support system notifications.");
                    } else if (Notification.permission === "granted") {
                        // If it's okay let's create a notification
                        var notification = new Notification(notificationTitle,notificationOptions);
                        // notification.onclick = function(event) {
                        //     event.preventDefault();
                        //     window.open(payload.notification.click_action , '_blank');
                        //     notification.close();
                        // }
                    }
                });
            }
        }
    }, []);

    useEffect(() => {
        if (origin.data !== null) {
            setData(origin.data);
            errors = {};
        }
    }, [origin.data]);

    useEffect(() => {
        if (notification.savedToken !== undefined && notification.savedToken !== null) {
            console.log("Token saved");
            localStorage.setItem("tokenSaved",true);
            notification.savedToken = undefined
        }
    }, [notification.savedToken]);

    return (
        <div>
            {/*<Helmet>*/}
            {/*    <meta charSet="utf-8" />*/}
            {/*    <title>{(metaInfo[history.location.pathname.replace(/\//g,"")] && metaInfo[history.location.pathname.replace(/\//g,"")].title) || "Best Indian Stock Market Advisor Company in India | Bullish India"}</title>*/}
            {/*    <meta name="description" content={(metaInfo[history.location.pathname.replace(/\//g,"")] && metaInfo[history.location.pathname.replace(/\//g,"")].description) || "Looking for Share Market Consultant? Bullish India is one of the best Equity Advisor Company in India. To get regular Free Stock Advisory, keep following the best Stock Advisory Firm Bullish India"} />*/}
            {/*    <meta property="og:title" content={(metaInfo[history.location.pathname.replace(/\//g,"")] && metaInfo[history.location.pathname.replace(/\//g,"")].title) || "Best Indian Stock Market Advisor Company in India | Bullish India"} />*/}
            {/*    <meta property="og:description" content={(metaInfo[history.location.pathname.replace(/\//g,"")] && metaInfo[history.location.pathname.replace(/\//g,"")].description) || "Looking for Share Market Consultant? Bullish India is one of the best Equity Advisor Company in India. To get regular Free Stock Advisory, keep following the best Stock Advisory Firm Bullish India"}/>*/}
            {/*    <link rel="canonical" href={"https://bullishindia.com" + history.location.pathname.replace(/\//g,"") } />*/}
            {/*</Helmet>*/}
            <OriginHeader/>
            <OriginNav/>

            <Script
                url="https://embed.tawk.to/597079d31dc79b329518f515/default"
            />

            <Switch>

                <Route exact path='/' component={OriginHomePage}/>
                {/*<Redirect from='/index.php' to='/' />*/}

                {/*<Route path="*" component={LoadableRoutes}/>*/}

                <Route exact path='/about' component={LoadableAboutPage}/>
                {/*<Redirect from='/about.php' to='/about' />*/}

                <Route exact path='/subscribe' component={LoadableSubscribePage}/>
                {/*<Redirect from='/subscribe.php' to='/subscribe' />*/}

                <Route exact path='/multibagger-stocks' component={LoadableMultibaggerPage}/>
                {/*<Redirect from='/multibagger-stocks.php' to='/multibagger-stocks' />*/}

                <Route exact path='/intradaycalls' component={IntradayPage}/>
                {/*<Redirect from='/intradaycalls.php' to='/intradaycalls' />*/}

                <Route exact path="/blog" component={LoadableBlogPage}/>
                {/*<Redirect from='/blog.php' to='/blog' />*/}

                <Route exact path="/contact" component={ContactPage}/>
                {/*<Redirect from='/contact.php' to='/contact' />*/}

                <Route exact path="/daily-news" component={LoadableNewsFeedFull}/>
                {/*<Redirect from='/daily-news.php' to='/daily-news' />*/}

                <Route exact path="/privacy-policy" component={LoadablePrivacyPolicy}/>
                {/*<Redirect from='/privacy-policy.php' to='/privacy-policy' />*/}

                <Route exact path="/terms-and-conditions" component={LoadableTermsPage}/>
                {/*<Redirect from='/terms-and-conditions.php' to='/terms-and-conditions' />*/}

                <Route exact path="/disclaimer" component={LoadableDisclaimerPage}/>
                {/*<Redirect from='/disclaimer.php' to='/disclaimer' />*/}

                <Route exact path="/delete-account" component={LoadableDeleteAccountPage}/>

                <Route path='*' render={() => <RedirectWrapper to="/not-found" />}/>
                {/*<Redirect from='*' to="/not-found"/>*/}

            </Switch>


        </div>
    )
}


const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
    origin: state.origin,
    notification: state.notification
});

export default withRouter(connect(mapStateToProps, {
    getOriginData,
    saveGlobalToken
})(Origin));
