import {
    ADD_ALERT,
    GET_BLOG_POSTS,
    GET_ERRORS,
    NEW_PASSWORD,
    RECOVER_PASSWORD,
    REFRESH_TOKEN,
    SET_CURRENT_USER
} from "./types";
import axios from "axios";
import {setAuthToken} from "../Utils/Auth";
import jwt_decode from 'jwt-decode';
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {handleResponse, negativeResponse} from "../Utils/ResponseManager";

export const setCurrentUser = (decoded) => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};

export const loginUser = userData => dispatch => {

    const requestData = {
        username: userData.username,
        password: userData.password
    };
    // var instance = axios.create({ baseURL: 'http://localhost:8080' });

    axios.post("/api/users/login", requestData)
        .then(res => {
            //save to local storage
            if (res.data.success) {
                const {token} = res.headers;
                const decoded = jwt_decode(token);
                if(userData.remember)
                    localStorage.setItem('jwtToken', token);
                setAuthToken(token);
                dispatch(setCurrentUser(decoded));
            }else{
                dispatch({
                    type: GET_ERRORS,
                    payload: {message: res.data.message, ...res.data.error}
                })
            }
        })
        .catch(err => {
                console.log(err);
            dispatch({
                type: GET_ERRORS,
                payload: {message: err.message, first: err.message}
            })
            }
        );
};

export const getUsers = requestData => dispatch => {
    axios.get("/api/users/", requestData)
        .then(res => {
            console.log(res);
            if (res.data.success) {
                // dispatch(setCurrentUser(decoded));
            }else{
                dispatch({
                    type: GET_ERRORS,
                    payload: {message: res.data.message, ...res.data.error}
                })
            }
        })
        .catch(err => {
                console.log(err);
                dispatch({
                    type: GET_ERRORS,
                    payload: {message: err.message, first: err.message}
                })
            }
        );
};

export const refreshToken = () => dispatch => {
    axios.get("/api/users/refresh-token")
        .then(res => {
            if (res.data.success) {
                const token = res.data.data;
                const decoded = jwt_decode(token);
                localStorage.setItem('jwtToken', token);
                setAuthToken(token);
                dispatch(setCurrentUser(decoded));
            }else{
                dispatch({
                    type: GET_ERRORS,
                    payload: {message: res.data.message, ...res.data.error}
                })
            }
            // dispatch(handleResponse(res.data,REFRESH_TOKEN));
        }).catch(err => {
        dispatch(negativeResponse(err));
    });
};

export const recoverPassword = requestData => dispatch => {
    dispatch(showLoading());
    axios.post('/api/users/password-recovery',requestData)
        .then(res => {
            dispatch(hideLoading());
            dispatch(handleResponse(res.data,RECOVER_PASSWORD));
        }).catch(err => {
        dispatch(hideLoading());
        dispatch(negativeResponse(err));
    });
};

export const saveNewPassword = requestData => dispatch => {
    dispatch(showLoading());
    axios.post('/api/users/password-reset',requestData)
        .then(res => {
            dispatch(hideLoading());
            dispatch(handleResponse(res.data,NEW_PASSWORD));
        }).catch(err => {
        dispatch(hideLoading());
        dispatch(negativeResponse(err));
    });
};

export const logoutUser = () => dispatch => {
    localStorage.removeItem('jwtToken');
    setAuthToken(false);
    dispatch(setCurrentUser({}));
};

export const setError = error => dispatch => {
    dispatch({
        type: GET_ERRORS,
        payload: error
    })
};
//
// export const setError = (error) =>{
//     return dispatch =>{
//         dispatch({
//             type: GET_ERRORS,
//             payload: error
//         })
//     };
// };
