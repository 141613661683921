import lodash from 'lodash';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import React, {forwardRef, useEffect} from "react";
import IntraItem from "./IntraItem";


function IntradayFeeder({ intradayList,errors, auth, /* functions */ }) {

    useEffect(() => {
        // Initialization
        // if (_.isEmpty(data)) {
        //     getData({});
        // }
    }, []);

    // change in data
    // useEffect(() => {
    //     if (!_.isEmpty(redcer.data)) {
    //         setData(redcer.data);
    //         errors = {};
    //     }
    // }, [redcer.data]);



    return (
        <div>
            {lodash.chunk(intradayList,3).map(chunk=>{
                return <div className="row m-auto text-center w-80 justify-content-center">
                    {
                        chunk.map(data=>{
                            return <IntraItem intraData={data} key={data._id}/>
                        })
                    }
                </div>
            })}
        </div>
    )
}


const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default withRouter(connect(mapStateToProps, {
    //functions
})(IntradayFeeder));
