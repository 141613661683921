import * as firebase from "firebase/app";


// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/messaging";
import "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAwjWcjkGFTU02nzRdGcyaDEQzVsz6c4TQ",
    authDomain: "bullish-india.firebaseapp.com",
    databaseURL: "https://bullish-india.firebaseio.com",
    projectId: "bullish-india",
    storageBucket: "bullish-india.appspot.com",
    messagingSenderId: "991650910039",
    appId: "1:991650910039:web:cd0297ada70978ab",
    measurementId: "G-R7VFFQ3VPX"
};

firebase.initializeApp(firebaseConfig);
let messaging;
if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging();
    messaging.usePublicVapidKey("BAXzo2GIdKBSDZuqXDkNW2hwnXhk8g-rnxGDayMkxlVy4v5g9pPLZRGP5mCuM3DnngKwMH_AsfHDU2zOiV5CJRM");
}
export const storageRef = firebase.storage().ref();
export const Firebase = firebase;
export const Messaging =messaging;
export const Analytics =firebase.analytics();
