import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import React, { useEffect} from "react";
import ContactHeader from "../../Components/Origin/Contact/ContactHeader";
import GetInTouchInfo from "../../Components/Origin/Common/GetInTouchInfo";
import BankInfo from "../../Components/Origin/Common/BankInfo";
import ContactForm from "../../Components/Origin/Contact/ContactForm";
import OriginFooter from "../../Components/Origin/OriginFooter";
import _ from "lodash";
import {Helmet} from "react-helmet";


function ContactPage({ redcer,errors, auth, origin}) {

    useEffect(() => {
        // Initialization
        // if (_.isEmpty(data)) {
        //     getData({});
        // }
    }, []);

    // change in data
    // useEffect(() => {
    //     if (!_.isEmpty(redcer.data)) {
    //         setData(redcer.data);
    //         errors = {};
    //     }
    // }, [redcer.data]);



    return (
        <div>
            {/*{ origin.data && origin.data.config &&*/}
            {/*    <Helmet>*/}
            {/*        <meta charSet="utf-8" />*/}
            {/*        {!_.isEmpty(origin.data.config.contactTitle) && <title>{origin.data.config.contactTitle}</title>}*/}
            {/*        {!_.isEmpty(origin.data.config.contactDesc) && <meta name="description" content={origin.data.config.contactDesc} />}*/}
            {/*        {!_.isEmpty(origin.data.config.contactTitle) && <meta property="og:title" content={origin.data.config.contactTitle} />}*/}
            {/*        {!_.isEmpty(origin.data.config.contactDesc) && <meta property="og:description" content={origin.data.config.contactDesc}/>}*/}
            {/*        <link rel="canonical" href="https://bullishindia.com/contact" />*/}
            {/*    </Helmet>*/}
            {/*}*/}

            <ContactHeader/>

            <section className="main-container">
                <div className="container">
                    <div className="row">
                        {/* main start */}
                        {/* ================ */}
                        <div className="main col-md-8 space-bottom">
                            <ContactForm/>
                        </div>
                        {/* main end */}
                        {/* sidebar start */}
                        {/* ================ */}
                        <div className="col-md-4 col-lg-4">
                            <GetInTouchInfo/>
                            <BankInfo/>
                            {/* sidebar end */}
                        </div>
                    </div>
                </div>
            </section>
            <OriginFooter/>
        </div>
    )
}


const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
    origin: state.origin,
});

export default withRouter(connect(mapStateToProps, {
    //functions
})(ContactPage));
