import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import React, {forwardRef, useEffect} from "react";
import _ from "lodash";
import IntradayHeader from "../../Components/Origin/Intraday/IntradayHeader";
import {getIntraday} from "../../actions/intradayActions";
import OriginLoader from "../../Components/Origin/Common/OriginLoader";
import IntradayFeeder from "../../Components/Origin/Intraday/IntradayFeeder";
import OriginFooter from "../../Components/Origin/OriginFooter";
import {makeStyles} from "@material-ui/core";
import classnames from 'classnames'
import Grid from "@material-ui/core/Grid";
import {Helmet} from "react-helmet";

const useStyles = makeStyles(theme => ({
    intraContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    bgGradient: {
        // background: "#C9D6FF";
        // background: "-webkit-linear-gradient(to right, #E2E2E2, #C9D6FF)";
        background: "linear-gradient(to right, #E2E2E2, #C9D6FF)"
    },
    ulli: {
        marginBottom: "1.4rem"
    },
    pricingDivider: {
        /*border-radius: 20px;*/
        background: "#C64545",
        padding: "1em 0 4em",
        position: "relative"
    },
    blue__pricing_divider: {
        "background": "#2D5772"
    },
    green__pricing_divider: {
        "background": "#1AA85C"
    },
    red_b: {
        "color": "#C64545"
    },
    blue_b: {
        "color": "#2D5772"
    },
    green_b: {
        "color": "#1AA85C"
    },
    pricing_divider_img: {
        "position": "absolute", "bottom": "-2px", "left": "0", "width": "100%", "height": "80px"
    },
    deco_layer: {
        "WebkitTransition": "-webkit-transform 0.5s", "transition": "transform 0.5s"
    },
    btn_custom: {
        "background": "#C64545", "color": "#fff", "borderRadius": "20px"
    },
    img_float: {
        "width": "50px", "position": "absolute", "top": "-3.5rem", "right": "1rem"
    },
    princing_item: {
        "transition": "all 150ms ease-out"
    },
    princing_item_hover: {
        "transform": "scale(1.05)"
    },
    princing_item_hover__deco_layer__1: {
        "WebkitTransform": "translate3d(15px, 0, 0)",
        "transform": "translate3d(15px, 0, 0)"
    },
    princing_item_hover__deco_layer__2: {
        "WebkitTransform": "translate3d(-15px, 0, 0)",
        "transform": "translate3d(-15px, 0, 0)"
    }
}));


function IntradayPage({intraday, getIntraday, errors, auth, origin}) {
    const [data, setData] = React.useState([]);
    const classes = useStyles();

    useEffect(() => {
        if (_.isEmpty(intraday.intradays)) {
            getIntraday({});
        }
    }, []);

    useEffect(() => {
        if (!_.isEmpty(intraday.intradays)) {
            setData(intraday.intradays);
            errors = {};
        }
    }, [intraday.intradays]);


    return (
        <div>
            {/*{ origin.data && origin.data.config &&*/}
            {/*    <Helmet>*/}
            {/*        <meta charSet="utf-8" />*/}
            {/*        {!_.isEmpty(origin.data.config.intraTitle) && <title>{origin.data.config.intraTitle}</title>}*/}
            {/*        {!_.isEmpty(origin.data.config.intraDesc) && <meta name="description" content={origin.data.config.intraDesc} />}*/}
            {/*        {!_.isEmpty(origin.data.config.contactTitle) && <meta property="og:title" content={origin.data.config.contactTitle} />}*/}
            {/*        {!_.isEmpty(origin.data.config.intraDesc) && <meta property="og:description" content={origin.data.config.intraDesc}/>}*/}
            {/*        <link rel="canonical" href="https://bullishindia.com/intradaycalls" />*/}
            {/*    </Helmet>*/}
            {/*}*/}

            <IntradayHeader/>
            <div>

                <div className={classes.intraContainer}>
                    <Grid container justify="center">
                        <Grid item xs={12} md={10}>
                            <div className={classnames(classes.bgGradient, "container-fluid p-3")}
                                 id="intradayContainer">
                                {data.length == 0 ? <OriginLoader/> : <IntradayFeeder intradayList={data}/>}
                                <div className="row m-auto text-center w-80">

                                    <div>
                                        <h3>What is Intraday Trading?</h3>
                                        <p style={{textAlign: 'justify'}}>The word ‘Intraday’ means ‘occuring within the
                                            course
                                            of one day’. This
                                            type of trading which involves the movement of equity, shares, stocks, index
                                            futures, index options,
                                            and any significant commodity within the same day, building upon the
                                            advantages of
                                            price moves
                                            throughout the day is known as Intraday Trading.
                                            Furthermore, intraday trading calls give the recommendations to either buy
                                            or sell a
                                            financial
                                            instrument (equity, shares, stocks, index futures, index options) after
                                            having a
                                            deep reflection on
                                            technical data points where the positions have to be inserted or deleted on
                                            the same
                                            trading day.
                                            Intraday is often used to call attention to the new highs and lows of a
                                            commodity up
                                            for trade. For
                                            example, ‘a new intraday high’ refers to a commodity that has reached a
                                            relatively
                                            new higher market
                                            value during a trading session. This can also be equal to the closing price
                                            for some
                                            cases.
                                        </p>
                                        <h3>Square off in Trading</h3>
                                        <p style={{textAlign: 'justify'}}>Traders involved in intraday trading keep a
                                            close
                                            track of intraday price
                                            movement by making use of graphical representations and real-time charts in
                                            order to
                                            build a
                                            strategy and maximize their benefits during the short-lasting price
                                            fluctuations. A
                                            technique called
                                            the Square Off is majorly used by the intraday investors to reverse the
                                            position and
                                            book profit or
                                            loss.</p>
                                        <h3>Example of Intraday Trading</h3>
                                        <p style={{textAlign: 'justify'}}>Consider an example where a trader buys 150
                                            shares of
                                            Facebook Inc on
                                            some given date at Rs. 1500 around 10 am. The trader then sells these 150
                                            shares on
                                            the same day at
                                            around 2:30 pm at Rs. 1530.
                                            In this situation, the trader is squaring off his position in the given
                                            trading
                                            session and is
                                            generating a profit of Rs. 30 per share. With a total of 150 shares, the
                                            trader has
                                            made a
                                            cumulative profit of Rs. 4500. This is a classic example of intraday
                                            trading.
                                            Just as a coin has two sides, Intraday Trading too has a lot of advantages
                                            and
                                            disadvantages
                                            associated with it.
                                        </p>
                                        <div className="col-12">
                                            <h3 style={{textAlign: 'left'}}>Advantages of Intraday Trading:</h3>
                                        </div>
                                        <div className="col-12">
                                            <ul style={{textAlign: 'left'}}>
                                                <li>No overnight risk as the position is not affected by any negative
                                                    news or
                                                    events.
                                                </li>
                                                <li>Flexibility to trade even with a limited capital base.</li>
                                                <li>Provides an opportunity to work from anywhere at your comfort.</li>
                                                <li>Allows you to utilize tight stop-loss orders</li>
                                                <li>Meager brokerage with many brokers giving as low as one paise for
                                                    intraday
                                                    trading.
                                                </li>
                                                <li>Perfect opportunity for traders to learn more and access
                                                    leverages.
                                                </li>
                                            </ul>
                                        </div>
                                        <h3>Disadvantages of Intraday Trading:</h3>
                                        <p style={{textAlign: 'justify'}}>Having a practical approach and focussing on
                                            the
                                            damages that can be
                                            caused by Intraday trading is highly imperative to carry out a decent
                                            trade.</p>
                                        <ul style={{textAlign: 'left'}}>
                                            <li><h5>Increased degree of risk</h5> - As it is a short-term trading, the
                                                decisive
                                                abilities of the
                                                trader should be highly polished. Any wrong decision can lead to
                                                humungous
                                                losses within a span
                                                of seconds.
                                            </li>
                                            <li><h5>Quality and Quantity</h5> - Traders are generally inclined towards
                                                making
                                                more number of
                                                profitable transactions while the investors focus on the quality of the
                                                trade.
                                            </li>
                                            <li><h5>Time Consuming</h5> - Demands a lot of time and energy from the
                                                individual.
                                                The trader must
                                                not perform any other job or task simultaneously.
                                            </li>
                                            <li><h5>Requires knowledge</h5> - Intraday Trading needs the trader to have
                                                some
                                                amount of knowledge
                                                about the various trading strategies and technicalities.
                                            </li>
                                            <li><h5>Patience and Perseverance</h5> - Creating wealth through this
                                                trading
                                                requires a lot of
                                                waiting instead of indecisive buying-selling and requires strict
                                                attention along
                                                with mental
                                                strength to pull off an efficient trade.
                                            </li>
                                            <br/>
                                            <p style={{textAlign: 'justify'}}>
                                                After considering varied factors linked to Intraday Trading, here are
                                                some
                                                unique tips and
                                                strategies for all the enthusiastic amateur and experienced traders.
                                            </p>
                                        </ul>
                                        <h3>Tips and Strategies for Intraday Trading:</h3>
                                        <ul style={{textAlign: 'left'}}>
                                            <li><h5>Tip 1- Have Persistence In Gaining Profits:</h5>Patience is the key
                                                to
                                                intraday trading. Do
                                                not be in a hurry to buy and sell commodities to raise profits and try
                                                to last
                                                through the price
                                                movements for the entire day’s session.
                                            </li>
                                            <li><h5>Tip 2- Less is More:</h5> Focussing your strategies on fewer stocks
                                                is much
                                                more efficient
                                                and profitable than to focus on too many stocks at the same time. Thus,
                                                avoid
                                                developing your
                                                interests in a variety of stocks.
                                            </li>
                                            <li><h5>Tip 3- Gracefully cut the losses:</h5> If the outcome is not what
                                                you
                                                expected, settle in
                                                and exit the trade using the stop loss principle, keeping in mind the
                                                profits
                                                and loss that you
                                                are ready to bear.
                                            </li>
                                            <li><h5>Tip 4- Try not to become an Investor involuntarily:</h5> If the
                                                commodity
                                                has not given the
                                                anticipated result, do not let it turn into an investment involuntarily.
                                                The
                                                whole idea behind
                                                intraday trading is to bring profits to reality, with no open positions
                                                at the
                                                end of the
                                                session.
                                            </li>
                                            <li><h5>Tip 5- Be Emotionally Intelligent:</h5> Technical analysis and
                                                principles
                                                are secondary keys
                                                to this trading. Intraday Trading wants you to be emotionally stable as
                                                it is
                                                quite demanding on
                                                the nerves. Sometimes, one can benefit a lot by paying heed to the
                                                uneasiness of
                                                trade, while
                                                squaring off offers a major relief. Focus on this part
                                            </li>
                                            <li><h5>Tip 6- Strategize:</h5> Make sure that you plan out the execution of
                                                the
                                                trade in such a way
                                                that you get maximum profit and fewer losses. Focus on analyzing and
                                                buying a
                                                watermelon with a
                                                lesser number of seeds.
                                            </li>
                                            <li><h5>Tip 7- Evaluate your mistakes:</h5> If you incur losses continuously
                                                over a
                                                period of time,
                                                then sit back and ponder over the mistakes that you are making. Make
                                                notes and
                                                try to find out a
                                                reason or a pattern behind the failure. This will definitely increase
                                                your
                                                confidence and
                                                reasoning abilities.
                                            </li>
                                            <li><h5>Tip 8- Have Realistic Goals:</h5> With predefined entry and exit
                                                methods,
                                                set a limit to the
                                                amount of risk that comes with each trade. Having a strategized goal
                                                does not
                                                mean that it will
                                                lead to profit always, however, it should give you a calculated ratio
                                                profit and
                                                loss that does
                                                not hurt you in the long run.
                                            </li>
                                            <li><h5>Tip 9- Do not buy in on margins:</h5> Buying in on margins means
                                                that
                                                borrowing capital from
                                                a broker to carry out a trade. This can be quite detrimental to your
                                                capital
                                                resources. Using
                                                one’s own capital to buy stocks is the only intelligent way to make a
                                                trade.
                                                Client-Broker
                                                relationship is quite parasitic in nature. If the client will prosper,
                                                the
                                                broker will fail.
                                            </li>
                                            <li><h5>Tip 10- Health is the biggest wealth:</h5> It is very crucial to
                                                think about
                                                yourself if you
                                                are active in intraday trading. Take short breaks and relax your mind in
                                                order
                                                to avoid undue
                                                pressure in the race to make profits.
                                            </li>
                                        </ul>
                                        <h3>Who Should Engage in Intraday Trading?</h3>
                                        <ul style={{textAlign: 'left'}}>
                                            <li><h5>Basic Capital Requirement:</h5> Having enough liquidity in account
                                                ensures
                                                and brings out a
                                                sense of security if you are involved in intraday trading. Individuals
                                                with
                                                sufficient capital
                                                can buy multiple stocks at the same time whereas those with a limited
                                                capital
                                                think of making
                                                larger profits through smaller inputs by intraday trading. These people
                                                with
                                                limited resources
                                                can generate profitable trades by having the right mindset and
                                                motivation.
                                            </li>
                                            <li><h5>Personal Factors:</h5> Intraday Trading is for those individuals who
                                                desire
                                                to gain a profit
                                                on daily basis and want to maintain a stable portfolio. Participants
                                                that
                                                require certain money
                                                over different time frames with anticipated return rates, and compunding
                                                do not
                                                want to sit for
                                                intraday trading.
                                            </li>
                                            <li><h5>Availability of Time:</h5> Every day at Intraday trading is a new
                                                lesson.This lesson
                                                requires one to judiciously learn new trends, analyze, open positions,
                                                and
                                                monitor progress. For
                                                a person who is engaged full time in a demanding profession, or business
                                                may not
                                                be the right
                                                fit for Intraday trading. This trading has tremendous profits to offer
                                                to people
                                                who can
                                                dedicate themselves with a lot of risk-taking abilities and
                                                decisiveness.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                </div>
            </div>
            <OriginFooter/>
        </div>
    )
}


const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
    intraday: state.intraday,
    origin: state.origin,
});

export default withRouter(connect(mapStateToProps, {
    getIntraday
})(IntradayPage));
