import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import React, {forwardRef, useEffect} from "react";
import {isFirefox} from '../../Utils/BrowserDetector';
import {Messaging} from "../../Utils/FirebaseHelper";
import {saveGlobalToken} from '../../actions/notificationActions';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faFacebookSquare,
    faInstagramSquare,
    faLinkedin,
    faPinterestSquare,
    faTwitterSquare, faWhatsapp
} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {makeStyles} from "@material-ui/core";
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
    headerContainer: {
        // display: "block",
        // width: "100%",
        // top: "0",
        // fontSize: 15,
        // fontWeight : 300,
    },
    headerTop: {
        // fontSize: "12px",
        // fontWeight: "300",
        // backgroundColor: "#000000",
        // borderBottom: "1px solid #252a2c",
        // color: "#ffffff",
        // paddingTop : "4px",
        // paddingBottom : "4px"
    },
    headerTopSecond: {
        // color: "#ffffff",
    },
    listInline : {
        // marginTop: 9,
        // marginBottom: 8,
        // marginLeft: -5,
        // display:"inline-block"
    },
    yellowLabel : {
        // color:"#ffbf12",
        // fontSize: "13px",
    },
    btnGroup :{
        // marginTop: 3,
        // marginBottom: 2,
    }

}));

function OriginHeader({redcer, errors, auth, saveGlobalToken}) {
    const classes = useStyles();

    useEffect(() => {
        // Initialization
        // if (_.isEmpty(data)) {
        //     getData({});
        // }
    }, []);

    // change in data
    // useEffect(() => {
    //     if (!_.isEmpty(redcer.data)) {
    //         setData(redcer.data);
    //         errors = {};
    //     }
    // }, [redcer.data]);

    const fcmSubscribe = () => {

        if (Messaging && Notification) {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    console.log('Notification permission granted.');
                    Messaging.getToken().then((currentToken) => {
                        if (currentToken) {
                            console.log(currentToken);
                            if (localStorage.getItem("tokenSaved") === "false") {
                                saveGlobalToken(currentToken)
                            }
                        }
                    }).catch((err) => {
                        localStorage.setItem("tokenSaved", false);
                        console.log('An error occurred while retrieving token. ', err);
                    });
                } else {
                    localStorage.setItem("tokenSaved", false);
                    console.log('Unable to get permission to notify.');
                }
            });
        }
    };


    return (
        <div className="page-wrapper">
            <div className={classnames(classes.headerContainer, "header-container")} style={{
                display: "block",
                width: "100%",
                top: "0",
                fontSize: 15,
                fontWeight : 300,
            }}>
                <div className={classnames(classes.headerTop)} style={{
                    backgroundColor: "#000000",
                    borderBottom: "1px solid #252a2c",
                    fontSize: "12px",
                    fontWeight: "300",
                    color: "#ffffff",
                    paddingTop : "4px",
                    paddingBottom : "4px"
                }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-2 col-sm-3 text-center">
                                <div className="header-top-first clearfix" style={{paddingTop: "11px"}}>
                                    <div style={{fontSize: "26px"}}>
                                        <a href="https://www.facebook.com/thebullishindia/" target="_blank">
                                            <FontAwesomeIcon icon={faFacebookSquare}/>
                                        </a> {" "}
                                        <a href="https://twitter.com/bullish_india" target="_blank">
                                            <FontAwesomeIcon icon={faTwitterSquare}/>
                                        </a>{" "}
                                        <a href="https://www.linkedin.com/company/bullish-india/" target="_blank">
                                            <FontAwesomeIcon icon={faLinkedin}/>
                                        </a>{" "}
                                        <a href="https://www.instagram.com/thebullishindia/" target="_blank">
                                            <FontAwesomeIcon icon={faInstagramSquare}/>
                                        </a>{" "}
                                        <a href="https://www.pinterest.com/bullishindia/" target="_blank">
                                            <FontAwesomeIcon icon={faPinterestSquare}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-4">
                                <div id="header-top-second"
                                     className={classnames(classes.headerTopSecond,"clearfix")} style={{
                                    color: "#ffffff",
                                }}>
                                    <div className="header-top-dropdown
                                 text-center">
                                        <ul className={classnames("list-inline",classes.listInline)} style={{
                                            marginTop: 9,
                                            marginBottom: 8,
                                            marginLeft: -5,
                                            display:"inline-block"
                                        }}>
                                            <li>Our SEBI Research Analyst No. is INH100004997</li>
                                            <br/>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-4">
                                <div id="header-top-second"
                                     className="clearfix text-center">
                                    <ul className={classnames(classes.listInline,"list-inline")} style={{
                                        marginTop: 9,
                                        marginBottom: 8,
                                        marginLeft: -5,
                                        display:"inline-block"
                                    }}>
                                        <li className="list-inline-item"><FontAwesomeIcon icon={faWhatsapp}
                                                             style={{marginLeft: 10, marginRight: 5}}/>+91-9988137985
                                        </li>
                                        <li className="list-inline-item"><FontAwesomeIcon icon={faEnvelope} style={{
                                            marginLeft: 10,
                                            marginRight: 5
                                        }}/> info@bullishindia.com
                                        </li>
                                    </ul>
                                    <div className={classnames(classes.btnGroup,"btn-group dropdown")} style={{
                                        marginTop: 3,
                                        marginBottom: 2,
                                    }}>

                                        <small className={classes.yellowLabel} style={{
                                            color:"#ffbf12",
                                            fontSize: "13px",
                                        }}>
                                            Our UPI ID For Subscription Payment<br/>
                                            <b>sanchi7chd@okicici</b>
                                            {" "}or Pay Direct to Our Bank A/c
                                        </small>

                                    </div>
                                </div>
                            </div>
                            {isFirefox && <div className="col-xs-12 col-sm-1">
                                <div id="header-top-second"
                                     className="clearfix text-center">
                                    <ul className="list-inline">
                                        <li></li>
                                        <li>
                                            <button className="xbtn xinfo" onClick={fcmSubscribe}>Get Alerts</button>
                                        </li>

                                    </ul>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}


const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default withRouter(connect(mapStateToProps, {
    saveGlobalToken
})(OriginHeader));
