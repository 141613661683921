import axios from "axios";
import {GET_ERRORS, GET_NOTIFICATIONS, SAVE_GLOBAL_FCM_TOKEN, SEND_NOTIFICATIONS} from "./types";
import {showLoading,hideLoading} from 'react-redux-loading-bar';
import {handleResponse,negativeResponse} from '../Utils/ResponseManager';

export const getNotifications = requestData => dispatch => {
    dispatch(showLoading());
    axios.get("/api/notifications", requestData)
        .then(res => {
            dispatch(hideLoading());
            dispatch(handleResponse(res.data,GET_NOTIFICATIONS));
        }).catch(err => {
            dispatch(negativeResponse(err));
        });
};

export const saveGlobalToken = token => dispatch => {
    dispatch(showLoading());
    axios.post("/api/fcm-global", {token})
        .then(res => {
            dispatch(hideLoading());
            dispatch(handleResponse(res.data,SAVE_GLOBAL_FCM_TOKEN));
        }).catch(err => {
        dispatch(hideLoading());
        dispatch(negativeResponse(err));
    });
};

export const sendWebNotification = requestData => dispatch => {
    dispatch(showLoading());
    axios.post("/api/notifications/web", requestData)
        .then(res => {
            dispatch(hideLoading());
            dispatch(handleResponse(res.data,SEND_NOTIFICATIONS));
        }).catch(err => {
            dispatch(negativeResponse(err));
        });
};

export const sendMobileNotification = requestData => dispatch => {
    dispatch(showLoading());
    axios.post("/api/users/notification", requestData)
        .then(res => {
            dispatch(hideLoading());
            dispatch(handleResponse(res.data,SEND_NOTIFICATIONS));
        }).catch(err => {
        dispatch(negativeResponse(err));
    });
};


