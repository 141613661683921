import {
    ADD_NEWS_FEED, CLEAR_NEWS_FEED, GET_LATEST_NEWS_FEEDS,
    GET_NEWS_FEEDS, GET_NEWS_FEEDS_STATS, UPLOAD_NEWS_FEED_IMAGES
} from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_NEWS_FEED:
            return {
                ...state,
                message : action.message,
                newNewsFeed: action.payload,
            };
        case UPLOAD_NEWS_FEED_IMAGES:
            return {
                ...state,
                message : action.message,
            };
        case CLEAR_NEWS_FEED:
            return {
                ...state,
                message : action.message,
                directoryCleared: true,
            };
        case GET_NEWS_FEEDS:
            return {
                ...state,
                newsFeeds: action.payload,
            };
        case GET_LATEST_NEWS_FEEDS:
            return {
                ...state,
                latestNewsFeed: action.payload,
            };
        case GET_NEWS_FEEDS_STATS:
            return {
                ...state,
                newsFeedsStats: action.payload,
            };
        default :
            return state;
    }
}
