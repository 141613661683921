import {GET_NOTIFICATIONS, SAVE_GLOBAL_FCM_TOKEN, SEND_NOTIFICATIONS} from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload.allNotifications,
                audience: action.payload.audience
            };
        case SEND_NOTIFICATIONS:
            return {
                ...state,
                message : action.message,
                newNotification: action.payload
            };
        case SAVE_GLOBAL_FCM_TOKEN:
            return {
                ...state,
                message : action.message,
                savedToken: action.payload
            };
        default :
            return state;
    }
}
