import {Redirect, withRouter} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Analytics} from "../../Utils/FirebaseHelper";


function RedirectWrapper({to, history}){
    const [ready, setReady] = useState(false);
    useEffect(() => {
        setReady(true);
        Analytics.logEvent('not-found', {
            source: window.location.href,
        });
    }, []);

    return ready ? <Redirect from='*' to="/not-found"/> : <div/>;
}

export default RedirectWrapper;