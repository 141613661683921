import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import React, {forwardRef, useEffect} from "react";
import Paper from "@material-ui/core/Paper";
import {
    AddBox,
} from "@material-ui/icons";
import _ from "lodash";

import QrCode from '../../../assets/img/bullishqr.png';

function BankInfo({ redcer,errors, auth, /* functions */ }) {

    useEffect(() => {
        // Initialization
        // if (_.isEmpty(data)) {
        //     getData({});
        // }
    }, []);

    // change in data
    // useEffect(() => {
    //     if (!_.isEmpty(redcer.data)) {
    //         setData(redcer.data);
    //         errors = {};
    //     }
    // }, [redcer.data]);



    return (
        <div className="pv-30 ph-20 white-bg feature-box bordered text-center" id="paymentBox">
            <h3 className="title"> ICICI Bank Details</h3>
            <div className="separator-2 mt-20 clearfix" />
            <ul className="list-unstyled small list-icons text-center">
                <li><strong className="text-default">BANK NAME</strong> <br />ICICI BANK</li>
                <hr />
                <ul className="list-unstyled small list-icons text-center">
                    <li><strong className="text-default">A/C HOLDER NAME</strong> <br />SANCHI ARORA</li>
                    <hr />
                    <li><strong className="text-default">Account Number</strong> <br />001301595419</li>
                    <hr />
                    <li><strong className="text-default">A/C TYPE</strong><br />SAVING</li>
                    <hr />
                    <li><strong className="text-default">Branch</strong><br />ICICI BANK, SECTOR 9D,CHANDIGARH</li>
                    <hr />
                    <li><strong className="text-default">IFSC CODE</strong><br />ICIC0000013</li>
                    <hr />
                    <img src={QrCode} style={{margin: 'auto'}} />
                    <li><strong className="text-default">UPI ADDRESS (VPA)</strong><br />sanchi7chd@okicici</li>
                    Remember to WhatsApp the UPI Transaction ID at +91 9988137985 to verify the transaction.
                    <hr />
                </ul>
            </ul>
        </div>

    )
}


const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default withRouter(connect(mapStateToProps, {
    //functions
})(BankInfo));
