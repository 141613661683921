import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import React, {forwardRef, useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faHome, faMobile} from "@fortawesome/free-solid-svg-icons";


function GetInTouchInfo({ redcer,errors, auth, /* functions */ }) {

    useEffect(() => {
        // Initialization
        // if (_.isEmpty(data)) {
        //     getData({});
        // }
    }, []);

    // change in data
    // useEffect(() => {
    //     if (!_.isEmpty(redcer.data)) {
    //         setData(redcer.data);
    //         errors = {};
    //     }
    // }, [redcer.data]);



    return (
        <div className="pv-30 ph-20 white-bg feature-box bordered text-center">
            <h3 className="title logo-font"> <span className="text-default">
                    Get in Touch</span></h3>
            <div className="separator-2 mt-20"/>
            <ul className="list text-left">
                <li><FontAwesomeIcon icon={faHome} style={{marginRight:10}} />
                    #1540 , SECTOR 22-B,
                    CHANDIGARH.
                </li>
                <li><FontAwesomeIcon icon={faMobile} style={{marginRight:10,marginLeft:5}}/><abbr
                    title="Phone">M:</abbr> +91-9988137985
                </li>
                <li><FontAwesomeIcon icon={faEnvelope} style={{marginRight:10}} /><a
                    href="mailto:info@bullishindia.com"> info@bullishindia.com</a></li>
            </ul>
            <div className="separator-2 mt-20 "/>
        </div>
    )
}


const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default withRouter(connect(mapStateToProps, {
    //functions
})(GetInTouchInfo));
