import {
     GET_MULTIBAGGER, UPDATE_MULTIBAGGER,
} from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_MULTIBAGGER:
            return {
                ...state,
                sections: action.payload,
            };
        case UPDATE_MULTIBAGGER:
            return {
                ...state,
                message : action.message,
                updatedMultiBagger: action.payload
            };
        default :
            return state;
    }
}
