import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import React, {forwardRef, useEffect} from "react";
import classnames from 'classnames'
import Logo from '../../assets/img/logo.png';


function OriginNav({ redcer,errors, auth, history}) {

    useEffect(() => {
        // Initialization
        // if (_.isEmpty(data)) {
        //     getData({});
        // }
    }, []);

    // change in data
    // useEffect(() => {
    //     if (!_.isEmpty(redcer.data)) {
    //         setData(redcer.data);
    //         errors = {};
    //     }
    // }, [redcer.data]);



    return (
        <div>
            <header className="header clearfix transparent-header-on" id="headerNormal" style={{
                borderTop: "1px solid #ffffff",
                borderBottom: "2px solid #f1f1f1",
                backgroundColor: "#fcfcfc"
            }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 hidden-xs hidden-sm">
                            {/* header-first start */}
                            {/* ================ */}
                            <div className="header-first clearfix">
                                {/* logo */}
                                <div id="logo" className="logo">
                                    <Link color="primary" href="javascript:;" onClick={() => history.push('/')}>
                                        <img id="logo_img" src={Logo} alt="Bullish India" />
                                    </Link>
                                </div>
                                {/* name-and-slogan */}
                            </div>
                            {/* header-first end */}
                        </div>
                        <div className="col-xs-9 col-md-12 col-lg-9">
                            <div className="header-second clearfix">
                                <div className="main-navigation  animated
                                 with-dropdown-buttons">
                                    {/* navbar start */}
                                    {/* ================ */}
                                    <nav className="navbar navbar-default" role="navigation" style={{
                                        marginBottom:0,
                                    }}>
                                        <div className="container-fluid">
                                            {/* Toggle get grouped for
                                   better mobile display */}
                                            <div className="navbar-header">
                                                <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-collapse-1">
                                                    <span className="sr-only">Toggle navigation</span>
                                                    <span className="icon-bar" />
                                                    <span className="icon-bar" />
                                                    <span className="icon-bar" />
                                                </button>
                                                <a href="index" className="hidden-lg hidden-md"><img id="logo_img" src={Logo} alt="Bullish India" /></a>
                                            </div>
                                            {/* Collect the nav links,
                                   forms, and other content for  toggling */}
                                            <div className="collapse
                                          navbar-collapse" id="navbar-collapse-1">
                                                {/* main-menu */}
                                                <ul className="nav
                                             navbar-nav navbar-right " style={{display: 'inline'}}>
                                                    {/* mega-menu start
                                           */}
                                                    <li>
                                                        <a href="/" style={{fontWeight: 'bold'}}>
                                                            Home
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/about"  style={{fontWeight: 'bold'}}>
                                                            About
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/subscribe" style={{fontWeight: 'bold'}}>
                                                            Subscribe
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/multibagger-stocks"  style={{fontWeight: 'bold'}}>
                                                            Multibagger Stocks
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/intradaycalls" style={{fontWeight: 'bold'}}>
                                                            Intraday Calls
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/blog"  style={{fontWeight: 'bold'}}>
                                                            Blog
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/contact" style={{fontWeight: 'bold'}}>
                                                            Contact
                                                        </a>
                                                    </li>
                                                </ul>
                                                {/* main-menu end */}
                                                {/* header buttons */}
                                                {/* header buttons
                                       end*/}
                                            </div>
                                        </div>
                                    </nav>
                                    {/* navbar end */}
                                </div>
                                {/* main-navigation end */}
                            </div>
                            {/* header-second end */}
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
}


const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default withRouter(connect(mapStateToProps, {
    //functions
})(OriginNav));
