const isOpera = ((!!window.opr) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0);


// Firefox 1.0+
const isFirefox = typeof InstallTrigger !== 'undefined';

// Internet Explorer 6-11
const isIE = /*@cc_on!@*/false || !!document.documentMode;

// Edge 20+
const isEdge = !isIE && !!window.StyleMedia;

// Chrome 1 - 71
const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

// Blink engine detection
const isBlink = (isChrome || isOpera) && !!window.CSS;

module.exports = {
    isFirefox,
    isIE,
    isEdge,
    isChrome,
    isBlink
};
