import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React, { forwardRef, useEffect } from 'react'
import styles from '../Styling/notFound.css'
import '../../assets/js/notFoundScript.NotFoundPage'

function NotFoundPage ({ redcer, errors, auth, /* functions */ }) {

  useEffect(() => {
    // Initialization
    // if (_.isEmpty(data)) {
    //     getData({});
    // }
  }, [])

  // change in data
  // useEffect(() => {
  //     if (!_.isEmpty(redcer.data)) {
  //         setData(redcer.data);
  //         errors = {};
  //     }
  // }, [redcer.data]);

  return (
    <div id="notfound">
      <div className="notfound_box">
        <div className="notfound_box__ghost">
          <div className="notfound_symbol"/>
          <div className="notfound_symbol"/>
          <div className="notfound_symbol"/>
          <div className="notfound_symbol"/>
          <div className="notfound_symbol"/>
          <div className="notfound_symbol"/>
          <div className="notfound_box__ghost-container">
            <div className="notfound_box__ghost-eyes">
              <div className="notfound_box__eye-left"/>
              <div className="notfound_box__eye-right"/>
            </div>
            <div className="notfound_box__ghost-bottom">
              <div/>
              <div/>
              <div/>
              <div/>
              <div/>
            </div>
          </div>
          <div className="notfound_box__ghost-shadow"/>
        </div>
        <div className="notfound_box__description">
          <div className="notfound_box__description-container">
            <div className="notfound_box__description-title">Whoops!</div>
            <div className="notfound_box__description-text">It seems like we couldn't find the page you were looking
              for
            </div>
          </div>
          <a href="/" className="notfound_box__button">Go back</a>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
})

export default withRouter(connect(mapStateToProps, {
  //functions
})(NotFoundPage))
