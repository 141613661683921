import {
    GET_INTRADAY,
    ADD_INTRADAY,
    DELETE_INTRADAY,
    UPDATE_INTRADAY
} from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_INTRADAY:
            return {
                ...state,
                intradays: action.payload,
            };
        case ADD_INTRADAY:
            return {
                ...state,
                message : action.message,
                newIntraday: action.payload
            };
        case UPDATE_INTRADAY:
            return {
                ...state,
                message : action.message,
                updatedIntraday: action.payload
            };
        case DELETE_INTRADAY:
            return {
                ...state,
                message : action.message,
                deletedIntraday: action.payload
            };
        default :
            return state;
    }
}
