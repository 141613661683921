import {
    GET_ALERTS,
    DELETE_ALERT, TOGGLE_TESTIMONIALS, TOGGLE_ALERT, ADD_ALERT, UPDATE_ALERT
} from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_ALERT:
            return {
                ...state,
                newAlert: action.payload,
            };
        case GET_ALERTS:
            return {
                ...state,
                alerts: action.payload,
            };
        case DELETE_ALERT:
            return {
                ...state,
                message : action.message,
                deletedAlert: action.payload
            };
        case TOGGLE_ALERT:
            return {
                ...state,
                message : action.message,
                updatedAlert: action.payload
            };
        case UPDATE_ALERT:
            return {
                ...state,
                message : action.message,
                updatedAlert: action.payload
            };
        default :
            return state;
    }
}
