import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import React, {forwardRef, useEffect} from "react";
import Paper from "@material-ui/core/Paper";
import {
    AddBox,
} from "@material-ui/icons";
import _ from "lodash";


function OriginLoader({ redcer,errors, auth, /* functions */ }) {

    useEffect(() => {
        // Initialization
        // if (_.isEmpty(data)) {
        //     getData({});
        // }
    }, []);

    // change in data
    // useEffect(() => {
    //     if (!_.isEmpty(redcer.data)) {
    //         setData(redcer.data);
    //         errors = {};
    //     }
    // }, [redcer.data]);



    return (
        <div className="row" id="intraLoaderLayout">
            <div className="col-md-2 col-md-offset-5 mt-20">
                <div className="loaderIntra"></div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default withRouter(connect(mapStateToProps, {
    //functions
})(OriginLoader));
