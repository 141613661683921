import {
    ADD_PACKAGE,
    ADD_TESTIMONIAL, DELETE_PACKAGE,
    DELETE_TESTIMONIALS, GET_PACKAGES,
    GET_TESTIMONIALS,
    TOGGLE_TESTIMONIALS, UPDATE_PACKAGE,
    UPDATE_TESTIMONIALS
} from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PACKAGES:
            return {
                ...state,
                packages: action.payload,
            };
        case ADD_PACKAGE:
            return {
                ...state,
                message : action.message,
                newPackage: action.payload
            };
        case UPDATE_PACKAGE:
            return {
                ...state,
                message : action.message,
                updatedPackage: action.payload
            };
        case DELETE_PACKAGE:
            return {
                ...state,
                message : action.message,
                deletedPackage: action.payload
            };
        default :
            return state;
    }
}
