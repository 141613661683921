import {GET_ERRORS} from "../actions/types";

export const handleResponse=(res,type,extra={})=>{
    if(res.success){
        return{
            type: type,
            payload: res.data,
            ...extra,
            message: res.message
        }
    }else{
        return {
            type: GET_ERRORS,
            payload: {message: res.message, ...res.error}
        }
    }
};
export const negativeResponse=err=>{
    console.log(err);
    return {
        type: GET_ERRORS,
        payload: {message: err.message, first: err.message}
    };
};

