import axios from "axios";
import {
    GET_ORIGIN_DATA
} from "./types";
import {showLoading,hideLoading} from 'react-redux-loading-bar';
import {handleResponse,negativeResponse} from '../Utils/ResponseManager';

export const getOriginData = requestData => dispatch => {
    dispatch(showLoading());
    axios.get("/api/dashboard/origin",requestData)
        .then(res => {
            dispatch(hideLoading());
            dispatch(handleResponse(res.data,GET_ORIGIN_DATA));
        }).catch(err => {
            dispatch(negativeResponse(err));
        });
};





