import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import React, {forwardRef, useEffect} from "react";
import _ from "lodash";
import {useInput} from "../common/UseInput";
import {useSnackbar} from "notistack";
import {addFeedback} from '../../actions/feedbacksActions';
import useScript from "../../Utils/UseScript";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faMapMarker, faMobile, faPencilAlt, faUser} from "@fortawesome/free-solid-svg-icons";
import Logo2 from '../../assets/img/logo2.png';

function OriginFooter({ redcer,errors, auth,feedback,addFeedback}) {

    const {value: name, setValue: setName, bind: bindName} = useInput("");
    const {value: email, setValue: setEmail, bind: bindEmail} = useInput("");
    const {value: mobile, setValue: setMobile, bind: bindMobile} = useInput("");
    const {value: message, setValue: setMessage, bind: bindMessage} = useInput("");
    const { enqueueSnackbar } = useSnackbar();
    useScript("https://platform.twitter.com/widgets.js");
    useScript("https://platform.linkedin.com/in.js");


    useEffect(() => {
        // Initialization
        // if (_.isEmpty(data)) {
        //     getData({});
        // }

    }, []);

    useEffect(() => {
        if (!_.isEmpty(feedback.newFeedback)) {
            enqueueSnackbar("Feedback Recorded", {variant : 'success',autoHideDuration: 2000});
            feedback.newFeedback={}
        }
    }, [feedback.newFeedback]);


    const feedbackSubmit = (e)=>{
        e.preventDefault();
        e.target.reset();
        let data = {
            name,
            email,
            mobile,
            message
        };
        setName("");
        setEmail("");
        setMobile("");
        setMessage("");
        addFeedback(data);
    };


    return (
        <footer id="footer" className="clearfix dark">
            {/* .footer start */}
            {/* ================ */}
            <div className="footer">
                <div className="container">
                    <div className="footer-inner">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="footer-content">
                                    <div className="logo-footer"><img id="logo-footer1" src={Logo2}
                                                                      alt="logo"/></div>
                                    <p>We're based at Chandigarh(India), and Registered with SEBI as a Research
                                        Analyst.</p>
                                    <ul className="list-inline mb-20">
                                        <li><FontAwesomeIcon icon={faMapMarker} style={{marginRight:5}} color="#09afdf"/>
                                            #1256 , Sector 34-C, Chandigarh
                                        </li>
                                        <li><FontAwesomeIcon icon={faMobile} style={{marginRight:5}} color="#09afdf"/> +91-9988137985</li>
                                        <li><a href="mailto:info@bullishindia.com" className="link-dark"><FontAwesomeIcon icon={faEnvelope} style={{marginRight:5}} color="#09afdf"/> info@bullishindia.com</a>
                                        </li>
                                    </ul>
                                    <div className="separator-2"/>
                                    <ul className="social-links circle margin-clear animated-effect-1">
                                        <li className="facebook">
                                            <iframe
                                                src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Fthebullishindia%2F&width=82&layout=button_count&action=like&size=small&share=false&height=21&appId=1191398334204763"
                                                width="82" height="21" style={{border:"none",overflow:"hidden"}}
                                                scrolling="no" frameBorder="0" allowtransparency="true"
                                                allow="encrypted-media"/>

                                        </li>
                                        <li className="twitter"><a
                                            href="https://twitter.com/bullish_india?ref_src=twsrc%5Etfw"
                                            className="twitter-follow-button" data-show-count="false">Follow
                                            @bullish_india</a>
                                        </li>
                                        <li className="linkedin">
                                            <script type="IN/Share" data-url="https://in.linkedin.com/company/bullish-india"></script>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="footer-content">
                                    <h2 className="title">Contact Us</h2>
                                    <form method="post" id="footerForm" name="footerForm"
                                          onSubmit={feedbackSubmit}>
                                        <div className="form-group position-relative mb-10">
                                            <label className="sr-only" htmlFor="first_name">Name</label>
                                            <input type="text" className="form-control" id="first_name"
                                                   {...bindName}
                                                   placeholder="Name" name="name"/>
                                            <FontAwesomeIcon icon={faUser} color="white" style={{top:15,right:15,position:"absolute"}}/>
                                        </div>
                                        <div className="form-group position-relative mb-10">
                                            <label className="sr-only" htmlFor="email">Email address</label>
                                            <input type="email" className="form-control" id="email"
                                                   placeholder="Enter email"
                                                   {...bindEmail}
                                                   pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" name="email"/>
                                            <FontAwesomeIcon icon={faEnvelope} color="white" style={{top:15,right:15,position:"absolute"}}/>
                                        </div>
                                        <div className="form-group position-relative mb-10">
                                            <label className="sr-only" htmlFor="email">Email Mobile</label>
                                            <input type="tel" className="form-control" id="mobile"
                                                   placeholder="Enter Mobile"
                                                   {...bindMobile}
                                                   pattern="[0123456789][0-9]{9}" name="mobile"/>
                                            <FontAwesomeIcon icon={faMobile} color="white" style={{top:15,right:18,position:"absolute"}}/>
                                        </div>
                                        <div className="form-group position-relative mb-10">
                                            <label className="sr-only" htmlFor="message">Message</label>
                                            <textarea className="form-control" rows={4} id="message"
                                                      {...bindMessage}
                                                      placeholder="Message" name="message" />
                                            <FontAwesomeIcon icon={faPencilAlt} color="white" style={{top:15,right:15,position:"absolute"}}/>
                                        </div>
                                        <input type="submit" id="submit" name="submit" defaultValue="Send"
                                               className="margin-clear submit-button btn btn-default"/>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* .footer end */}
            {/* .subfooter start */}
            {/* ================ */}
            <div className="subfooter">
                <div className="container">
                    <div className="subfooter-inner">
                        <div className="row">
                            <div className="col-md-12">
                                <p className="text-center">Copyright © 2017
                                    All Rights Reserved
                                    &nbsp;<a href="terms-and-conditions">Terms and Conditions</a>&nbsp;
                                    <a href="privacy-policy">Privacy Policy</a>&nbsp;
                                    <a href="disclaimer">Disclaimer</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* .subfooter end */}
        </footer>
    )
}


const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
    feedback : state.feedback
});

export default withRouter(connect(mapStateToProps, {
    addFeedback
})(OriginFooter));
