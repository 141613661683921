import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducers";

const preloadedState = window.__PRELOADED_STATE__;
const initialState = {};

const middleware = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  (preloadedState || initialState),
  composeEnhancers(
    applyMiddleware(...middleware)
  )
);

window.snapSaveState = () => ({
  __PRELOADED_STATE__: store.getState()
});

export default store;
