import axios from "axios";
import {
    GET_INTRADAY,
    ADD_INTRADAY,
    DELETE_INTRADAY,
    UPDATE_INTRADAY
} from "./types";
import {showLoading,hideLoading} from 'react-redux-loading-bar';
import {handleResponse,negativeResponse} from '../Utils/ResponseManager';

export const getIntraday = requestData => dispatch => {
    dispatch(showLoading());
    axios.get("/api/intra",requestData)
        .then(res => {
            dispatch(hideLoading());
            dispatch(handleResponse(res.data,GET_INTRADAY));
        }).catch(err => {
            dispatch(negativeResponse(err));
        });
};

export const addIntraday = requestData => dispatch => {
    dispatch(showLoading());
    axios.post('/api/intra',requestData)
        .then(res => {
            dispatch(hideLoading());
            dispatch(handleResponse(res.data,ADD_INTRADAY));
        }).catch(err => {
        dispatch(negativeResponse(err));
    });
};

export const deleteIntraday = requestData => dispatch => {
    dispatch(showLoading());
    axios.delete(`/api/intra/${requestData._id}`)
        .then(res => {
            dispatch(hideLoading());
            dispatch(handleResponse(res.data,DELETE_INTRADAY));
        }).catch(err => {
        dispatch(negativeResponse(err));
    });
};

export const updateIntraday = (requestData,newData) => dispatch => {
    dispatch(showLoading());
    axios.put(`/api/intra/${requestData._id}`,newData)
        .then(res => {
            dispatch(hideLoading());
            dispatch(handleResponse(res.data,UPDATE_INTRADAY));
        }).catch(err => {
        dispatch(negativeResponse(err));
    });
};




